/*
|--------------------
|     Bloc titre
|--------------------
*/
.sale-title {
    margin-bottom: 130px;
}

/*
|--------------------
|     Estimation en ligne
|--------------------
*/
.estimate-online {
    margin-bottom: 130px;
    .container {
        padding: 50px;
        border: 1px solid #000;
    }
    @include media-breakpoint-down(lg) {
        padding: 10px;
        background: #fff;
        .container {
            box-shadow: none !important;
        }
    }
    @include media-breakpoint-down(lg) {
        .container {
            padding: 50px 10px;
        }
    }
}

/*
|--------------------
|     Estimation Moriss Immobilier
|--------------------
*/
.estimate-moriss-immobilier {
    margin-bottom: $size-100;
    padding: 60px;
    position: relative;
    &-description {
        max-width: 400px;
        margin: 0 0 30px;
        @include media-breakpoint-down(md) {
            max-width: none;
        }
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        background: #fff;
    }
    &-wrap-image {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        img {
            max-width: none;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(70%, -50%);
            width: 458px;
            height: 430px;
            z-index: -1;
            background: url('../img/global/bk-moriss-gray.svg');
            background-size: contain;
        }
    }
    &-right {
        padding: 0 0 0 200px;
    }
    @include media-breakpoint-down(md) {
         background: #F7F5F0;
         &:before {
             display: none;
         }
    }

    @include media-breakpoint-down(sm) {
        padding: 50px 10px;
    }
}