/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/

/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
$font-size-xs :             0.75rem;
$screen-lg-font-size-xs :   $font-size-xs;
$screen-md-font-size-xs :   $font-size-xs;
$screen-sm-font-size-xs :   $font-size-xs;
$screen-xs-font-size-xs :   $font-size-xs;

$font-size-sm :             0.875rem;
$screen-lg-font-size-sm :   $font-size-sm;
$screen-md-font-size-sm :   $font-size-sm;
$screen-sm-font-size-sm :   $font-size-sm;
$screen-xs-font-size-sm :   $font-size-sm;

$font-size-md :             1.125rem;
$screen-lg-font-size-md :   $font-size-md;
$screen-md-font-size-md :   $font-size-md;
$screen-sm-font-size-md :   $font-size-md;
$screen-xs-font-size-md :   $font-size-md;

$font-size-lg :             1.75rem;
$screen-lg-font-size-lg :   $font-size-lg;
$screen-md-font-size-lg :   $font-size-lg;
$screen-sm-font-size-lg :   $font-size-lg;
$screen-xs-font-size-lg :   $font-size-lg;

$font-size-xl :             2.25rem;
$screen-lg-font-size-xl :   $font-size-xl;
$screen-md-font-size-xl :   $font-size-xl;
$screen-sm-font-size-xl :   $font-size-xl;
$screen-xs-font-size-xl :   $font-size-xl;

$font-size-xxl :            3.125rem;
$screen-lg-font-size-xxl :  $font-size-xxl;
$screen-md-font-size-xxl :  $font-size-xxl;
$screen-sm-font-size-xxl :  $font-size-xxl;
$screen-xs-font-size-xxl :  $font-size-xxl;

$size-10: 0.5rem;
$size-12: 0.75rem;
$size-14: 0.875rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-26: 1.75rem;
$size-28: 1.875rem;
$size-30: 2rem;
$size-32: 2.125rem;
$size-34: 2.25rem;
$size-36: 2.375rem;
$size-38: 2.5rem;
$size-40: 2.75rem;
$size-42: 2.875rem;
$size-44: 3rem;
$size-46: 3.125rem;
$size-48: 3.25rem;
$size-50: 3.375rem;
$size-52: 3.5rem;
$size-54: 3.75rem;
$size-56: 3.875rem;
$size-58: 4rem;
$size-60: 4.125rem;
$size-62: 4.25rem;
$size-64: 4.375rem;
$size-66: 4.5rem;
$size-68: 4.75rem;
$size-70: 4.875rem;
$size-72: 5rem;
$size-74: 5.125rem;
$size-76: 5.25rem;
$size-78: 5.375rem;
$size-80: 5.5rem;
$size-82: 5.75rem;
$size-84: 5.875rem;
$size-86: 6rem;
$size-88: 6.125rem;
$size-90: 6.25rem;
$size-92: 6.375rem;
$size-94: 6.5rem;
$size-96: 6.75rem;
$size-98: 6.875rem;
$size-100: 7rem;
$size-102: 7.125rem;
$size-104: 7.25rem;
$size-106: 7.375rem;
$size-108: 7.5rem;
$size-150: 9.375rem;


$font-size-10: $size-10;
$font-size-12: $size-12;
$font-size-14: $size-14;
$font-size-16: $size-16;
$font-size-18: $size-18;
$font-size-20: $size-20;
$font-size-22: $size-22;
$font-size-24: $size-24;
$font-size-26: $size-26;
$font-size-28: $size-28;
$font-size-30: $size-30;
$font-size-32: $size-32;
$font-size-34: $size-34;
$font-size-36: $size-36;
$font-size-38: $size-38;
$font-size-40: $size-40;
$font-size-42: $size-42;
$font-size-44: $size-44;
$font-size-46: $size-46;
$font-size-48: $size-48;
$font-size-50: $size-50;
$font-size-52: $size-52;
$font-size-54: $size-54;
$font-size-56: $size-56;
$font-size-58: $size-58;
$font-size-60: $size-60;
$font-size-62: $size-62;
$font-size-64: $size-64;
$font-size-66: $size-66;
$font-size-68: $size-68;
$font-size-70: $size-70;
$font-size-72: $size-72;
$font-size-74: $size-74;
$font-size-76: $size-76;
$font-size-78: $size-78;
$font-size-80: $size-80;





/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
$font-size-title-xs :             16px;
$screen-lg-font-size-title-xs :   $font-size-title-xs;
$screen-md-font-size-title-xs :   $font-size-title-xs;
$screen-sm-font-size-title-xs :   $font-size-title-xs;
$screen-xs-font-size-title-xs :   $font-size-title-xs;

$font-size-title-sm :             18px;
$screen-lg-font-size-title-sm :   $font-size-title-sm;
$screen-md-font-size-title-sm :   $font-size-title-sm;
$screen-sm-font-size-title-sm :   $font-size-title-sm;
$screen-xs-font-size-title-sm :   $font-size-title-sm;

$font-size-title-md :             24px;
$screen-lg-font-size-title-md :   $font-size-title-md;
$screen-md-font-size-title-md :   $font-size-title-md;
$screen-sm-font-size-title-md :   $font-size-title-md;
$screen-xs-font-size-title-md :   $font-size-title-md;

$font-size-title-lg :             30px;
$screen-lg-font-size-title-lg :   $font-size-title-lg;
$screen-md-font-size-title-lg :   $font-size-title-lg;
$screen-sm-font-size-title-lg :   $font-size-title-lg;
$screen-xs-font-size-title-lg :   $font-size-title-lg;

$font-size-title-xl :             50px;
$screen-lg-font-size-title-xl :   $font-size-title-xl;
$screen-md-font-size-title-xl :   42px;
$screen-sm-font-size-title-xl :   42px;
$screen-xs-font-size-title-xl :   32px;

$font-size-title-xxl :            80px;
$screen-lg-font-size-title-xxl :  $font-size-title-xxl;
$screen-md-font-size-title-xxl :  $font-size-title-xxl;
$screen-sm-font-size-title-xxl :  $font-size-title-xxl;
$screen-xs-font-size-title-xxl :  $font-size-title-xxl;


/*
|
| FONT-FAMILIES
|----------------
|
*/
$font-family-default :    'avenir', sans-serif;
$font-family-custom :     'avenir', sans-serif;
$font-family-extra :      'avenir', sans-serif;


/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
$font-families: (
  'default':    $font-family-default,
  'custom':     $font-family-custom,
  'extra':      $font-family-extra
);


/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
$letter-spacing-xs:   1px;
$letter-spacing-sm:   2px;
$letter-spacing-md:   3px;
$letter-spacing-lg:   4px;
$letter-spacing-xl:   5px;
$letter-spacing-xxl:  6px;


/*
|
| LINE-HEIGHT
|--------------
|
*/
$line-height-xs  : 1;
$line-height-sm  : 1.2;
$line-height-md  : 1.4;
$line-height-lg  : 1.4;
$line-height-xl  : 1.4;
$line-height-xxl : 2;


/*
|
| Default font settings
|------------------------
|
*/
$default-font-size   : $font-size-sm;
$default-color       : $text-color;
$default-line-height : $line-height-xl;
$default-font-weight : 200;


/*
|
| Default titles settings
|--------------------------
|
*/
$default-titles : (
  color       : inherit,
  font-family : $font-family-custom,
  font-weight : 400,
  line-height : 1.2
);