/*
|--------------------
|     Avis
|--------------------
*/
.avis-bloc {
    margin-bottom: 120px;
    &-filters {
        margin-bottom: 100px;
        ul {
            display: flex;
            justify-content: center;
            li {
                a {
                    color: #7E7E7E;
                }
                &.separator {
                    padding: 0 10px;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        &-filters {
            margin-bottom: 30px !important;
        }
        ul {
            display: block;
            text-align: center;
            li {
                border: none;
                margin-bottom: 10px;
            }
        }
    }
}