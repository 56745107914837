/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

@font-face {
    font-family: 'avenir';
    src:
            url('../fonts/avenir/Avenir-Light.eot?#iefix') format('embedded-opentype'),
            url('../fonts/avenir/Avenir-Light.otf') format('opentype'),
            url('../fonts/avenir/Avenir-Light.woff') format('woff'),
            url('../fonts/avenir/Avenir-Light.ttf') format('truetype'),
            url('../fonts/avenir/Avenir-Light.svg#Avenir-Light') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src:
            url('../fonts/avenir/Avenir-Regular.eot?#iefix') format('embedded-opentype'),
            url('../fonts/avenir/Avenir-Regular.otf') format('opentype'),
            url('../fonts/avenir/Avenir-Regular.woff') format('woff'),
            url('../fonts/avenir/Avenir-Regular.ttf') format('truetype'),
            url('../fonts/avenir/Avenir-Regular.svg#Avenir-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src:
            url('../fonts/avenir/Avenir-Bold.eot?#iefix') format('embedded-opentype'),
            url('../fonts/avenir/Avenir-Bold.otf') format('opentype'),
            url('../fonts/avenir/Avenir-Bold.woff') format('woff'),
            url('../fonts/avenir/Avenir-Bold.ttf') format('truetype'),
            url('../fonts/avenir/Avenir-Bold.svg#Avenir-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'adobegaramond';
    src:
            url('../fonts/adobegaramond/AdobeGaramond.eot?#iefix') format('embedded-opentype'),
            url('../fonts/adobegaramond/AdobeGaramond.otf') format('opentype'),
            url('../fonts/adobegaramond/AdobeGaramond.woff') format('woff'),
            url('../fonts/adobegaramond/AdobeGaramond.woff2') format('woff2'),
            url('../fonts/adobegaramond/AdobeGaramond.ttf') format('truetype'),
            url('../fonts/adobegaramond/AdobeGaramond.svg#AdobeGaramond') format('svg');
    font-weight: 400;
    font-style: normal;
}