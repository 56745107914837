/*
|--------------------
|     Agences
|--------------------
*/
.agencies-presentation {
    margin-bottom: 150px;
    .description {
        margin-bottom: 0;
    }
    .map {
        max-width: 600px;
    }
}

/*
|--------------------
|     Single
|--------------------
*/
/*
|--------------------
|     Présentation
|--------------------
*/
.agency-presentation {
    margin-bottom: 150px;
    &-description {
        margin-bottom: 20px;
    }
    &-address {
        font-size: $font-size-12;
        font-weight: 700;
        color: #333;
    }
    ul {
        li {
            &:first-child {
                margin-bottom: 17px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        &-address {
            margin-bottom: 20px;
        }
    }
    @include media-breakpoint-down(md) {
        ul {
            li {
                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
        .simpleParallax {
            margin-bottom: 20px;
        }
    }
}

/*
|--------------------
|     Equipe
|--------------------
*/
.agency-team {
    margin-bottom: 120px;
    .bloc-title {
        margin-bottom: 120px;
    }
    &-picture {
        img {
            max-width: 100%;
        }
        margin: 0 0 20px;
    }
}

/*
|--------------------
|     Biens
|--------------------
*/
.agency-products {
    margin-bottom: 120px;
    .bloc-title {
        margin-bottom: 120px;
    }
}

/*
|--------------------
|     Avis
|--------------------
*/
.agency-avis {
    margin-bottom: 120px;
    .bloc-title {
        margin-bottom: 120px;
    }
}