/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select{
    border-radius: 0;

    &:focus{
        outline: none;
    }
}

input {
    border: none;
    border-bottom: 1px solid #000;
    line-height: 2.6;
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap{
    .gform_fields{
        display: flex !important;
        flex-wrap: wrap !important;

        input, textarea{
            width: 100%;
            background-color: transparent;
        }
    }
}

/*
|
| Materialize form 
|-------------------
*/
.materialize-form{
    .materialize-form-group{
        position: relative;
        margin-bottom: 25px;

        label{
            position: absolute;
            top: 0;
            left: 15px;
            font-family: 'adobegaramond';
            font-size: $font-size-20 !important;
        }
        input{
            border: none;
            border-bottom: 1px $very-dark-grey solid;
            color: $very-dark-grey;
        }

        input[type=file] {
            border: none;
            height: auto;
        }

        &.gfield_error{
            label{
                position: static;
            }
        }
        
    }
}

/*
|
| Contact form 
|---------------
*/
.contact-form{
    label{
        color: $very-dark-grey;
        font-family: 'adobegaramond';
        font-size: $font-size-18 !important;
    }

    textarea{
        border: 1px $very-dark-grey solid;
        height: 120px;
        padding: 5px 10px;
        color: $very-dark-grey;

        &.placeholder_disabled{
            color: $very-dark-grey;
        }
    }

    .ginput_container_fileupload {
        padding: 0 0 0 50px;
    }

    .gform_body{
        margin-bottom: 20px;
    }

    .gform_footer {
        text-align: center;
    }

    input {
        width: 100% !important;
    }

    input[type="submit"]{
        @extend .btn;
    }

    .gform_confirmation_message{
        color: $very-dark-grey;
    }

    .validation_message{
        margin-top: 5px;
        color: #BB0B0B;
        font-size: 14px;
    }

    #field_1_6 label, #field_2_8 label, #field_3_44 label, #field_13_44 label {
        font-family: 'adobegaramond';
        font-size: $size-14;
    }

    #field_1_6 > label, #field_2_8 > label, #field_3_44 > label, #field_4_1 > label, #field_5_1 > label, #field_13_44 > label {
        display: none;
    }

    #choice_1_6_1, #choice_2_8_1, #choice_3_44_1, #choice_13_44_1 {
        margin-right: 20px;
    }
    #label_1_6_1, #label_2_8_1, #label_3_44_1, #label_13_44_1 {
        color: #7E7E7E;
        font-size: $font-size-14 !important;
    }

    #field_1_2, #field_1_5 {
        max-width: none !important;
        padding-right: 16px;
    }

    .gform_wrapper ul.gfield_checkbox li input[type=checkbox], .gform_wrapper ul.gfield_radio li input[type=radio]{
        width: auto !important;
    }

    #input_2_4 {
        font-size: $font-size-10;
    }

    @include media-breakpoint-down(sm) {
        #field_5_1 {

        }
    }
}


.validation_error{
    margin-bottom: 30px;
    color: #BB0B0B!important;
}

#gform_ajax_spinner_1 {
    //display: none;
    margin-left: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
}


/* Estimation en ligne */
#field_3_1,
#field_13_1 {
    & > label {
        display: none;
        margin-top: 0;
        margin-bottom: 10px;
    }
}

#input_3_1, #input_3_23, #input_4_1, #input_5_1, #input_13_1, #input_13_23 {
    display: flex;
    justify-content: left;
    li {
        text-align: center;
        margin: 0 20px;
        label {
            position: static;
            margin-left: 10px;
        }
        @include media-breakpoint-down(sm) {
            margin: 0;
        }
    }
}
#input_4_1, #input_5_1 {
    justify-content: center;
}

#choice_3_44_1,
#choice_13_44_1 {
    margin-top: 0;
}

.gform_page_footer {
    text-align: center;
    border: none !important;
}

.gf_progressbar {
    display: none;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    width: auto !important;
    margin: 30px 10px 10px;
    &:after {
        display: none !important;
    }
    &_percentage {
        text-shadow: none !important;
        background: #7c7c7c !important;
        border-radius: 0 !important;
        height: 2px !important;
        color: #000 !important;
    }
}

.gfieldset {
    padding: 0 10px 50px;
    margin-top: 50px;
    position: relative;
    legend {
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
        span {
            position: relative;
            display: inline-block;
            font-weight: 700;
            font-size: $size-16;
        }
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 0 0 50px;
    }
}

.filter-form label {
    position: static !important;
}

#field_3_47 {
    margin-top: 50px;
}

#gform_6,
#gform_14 {
    .gform_footer {
        text-align: center;
    }
}

#gform_confirmation_wrapper_6 {
    margin-top: $size-100;
}

.gform_validation_error.gform_wrapper .top_label .gfield_label, .gform_wrapper legend.gfield_label {
    position: static;
}