/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu{
    color: $very-dark-grey;
    font-size: $font-size-20;
    font-weight: 400;
}

#header-sticky-wrapper {
    z-index: 3;
    position: absolute;
    top: 30px;
    width: calc(100% - 60px);
    @include media-breakpoint-down(md) {
        width: calc(100% - 20px);
    }
}

#header{
    width: 100%;
    padding: 30px;
    .item-logo{
        svg{
            path, polygon{
                fill: $white;
            }
        }
    }

    .item-menu{
        li{
            &:not(:last-child){
                margin-right: 20px;
                @include media-breakpoint-down(lg) {
                    margin-right: 10px;
                }
            }

            .item-link {
                @extend .link-menu;
                @include media-breakpoint-down(xl) {
                    font-size: $font-size-14;
                }
            }
        }
    }

    .btn-menu {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        z-index: 10;
        padding: 5px;
        position: relative;
        border: 1px solid transparent;

        &>.item-burger {
            display: block;
            width: 20px;

            &>span {
                display: block;
                width: 100%;
                height: 1px;
                background-color: $very-dark-grey;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 4px 0;
                }
            }
        }

        &>.btn-menu-title {
            margin-left: 10px;
        }
    }

    .menu-left {
        .menu-item:first-child {
            z-index: 10;
            a {
                font-weight: 700;
            }
        }
    }

    .menu-center {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        @include media-breakpoint-down(md) {
            .item-logo {
                svg {
                    width: 80px;
                    height: auto;
                }
            }
        }

    }

    .menu-right {

        .menu-item:first-child, .menu-item:nth-child(2), .menu-item:nth-child(3), .menu-item:nth-child(4), .menu-item:last-child {
            .item-link, .btn-menu-title {
                font-weight: 700;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        padding: 10px 10px 0;
    }
    @include media-breakpoint-down(md) {
        .item-menu li:not(:last-child) {
            margin-right: 0;
        }
    }
}

#header {
    @include media-breakpoint-down(md) {
        .menu-item-384, .menu-item-386, .menu-item-387, .menu-item-388, .menu-item-2696,
        .menu-item-521, .menu-item-522, .menu-item-523, .menu-item-524, .menu-item-74470, .menu-item-74469 {
            display:none;
        }
    }
    @include media-breakpoint-down(sm) {
        .menu-left .item-menu, .btn-menu-title{
            display:none !important;
        }
    }
}

.is-sticky {
    #header {
        padding-bottom: 0;
        height: 0;
    }
    .menu-item-384, .menu-item-386, .menu-item-387, .menu-item-388, .menu-item-2696, .menu-center,
    .menu-item-521, .menu-item-522, .menu-item-523, .menu-item-524, .menu-item-74470, .menu-item-74469 {
        display: none;
    }
    .menu-left {
        .menu-item:first-child {
            .btn {
                background: #fff;
            }
        }
    }
    .btn-menu {
        border: 1px solid #000 !important;
        background: #fff !important;
    }
}

/*
|
| Menu
|-------
*/
#mobile-menu{
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $white;
    overflow: auto;
    display:none;

    .item-container{

        ul {
            & > li {

                a {
                    color: $very-dark-grey;
                    font-size: 2vw;
                    font-weight: 700;
                    line-height: 1.8;
                    -webkit-font-smoothing: antialiased;
                    padding: 0 1rem;
                    text-decoration: none;
                    position: relative;

                    &:after {
                        content:'';
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        bottom: 10px;
                        left: 0;
                        background-color: #000;
                        transform: scaleX(0);
                        transform-origin: bottom right;
                        transition: transform 0.3s;
                    }

                    &:hover {
                        &:after {
                            transform-origin: bottom left;
                            transform: scaleX(1);
                            background-color: #000;
                        }
                    }

                    @include media-breakpoint-down(xs) {
                        font-size: 26px;
                    }
                }

                &:last-child a{
                    color: #EAE6DB;
                    border: 1px solid #EAE6DB;
                    &:before, &:after {
                        display: none;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .item-container ul > li a:after {
            bottom: 5px !important;
        }
    }
}