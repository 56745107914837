/*
|--------------------
|      BUTTONS
|--------------------
*/

.btn {
    border: 1px solid #000;
    padding: 0;
    background: transparent;
    @include btn-border-drawing(#000, #000, 1px, bottom, left);
    position: relative;
    overflow: hidden;
    z-index: 2;
    color: $very-dark-grey;
    border-radius: 0;
    box-shadow: none;
    font-size: $font-size-18;
    span {
        display: block;
        padding: 5px 15px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-100%, 0);
            background: url('../img/icon/line.svg') center right transparent;
            width: 300px;
            height: 100%;
            transition: all 0.5s;
            z-index: -1;
        }
        &:hover:before {
            transform: translate(0, 0);
        }
    }
}

.btn.transition-white {
    span {
        &:before {
            background: url('../img/icon/line-white.svg') center right;
        }
    }
}

.btn.transition-white.btn-beige {
    background: #F7F5F0;
    span {
        &:before {
            background: url('../img/icon/line-white-big.svg') center right transparent;
            width: 400px;
        }
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
        width: auto;
        span {
            padding: 10px 30px;
        }
    }
}

.btn-big {
    span {
        padding: 10px 50px;
        font-size: $font-size-24;
        @include media-breakpoint-down(xl) {
            font-size: $font-size-18;
        }
    }
}

.btn-inverse {
    background: #000;
    color: #fff;
}

.logo-line-path {
    background: #F7F5F0;
}