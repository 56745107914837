/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
    box-sizing: border-box;
    overflow-x: hidden;
}

*{
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body{
    font-family: $font-family-default;
    color: $default-color;
    font-size: $default-font-size;
    line-height: $default-line-height;
    font-weight: 400;
    letter-spacing: 0.5px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
    padding: 30px;
    background: #f7f5f0;
    @include media-breakpoint-down(md) {
        padding: 10px;
    }
}

h1, h2, h3, h4, h5, h6{
    @each $property, $value in $default-titles{
        #{$property}: $value;
    }
}

button, input, textarea{
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

button{
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

img{
  max-width: inherit;
}

a, img, span, button{
    display: inline-block;
}

a{
    position: relative;
    transition: all 0.25s;
    &:active, &:focus { outline: none; box-shadow: none !important; color: #000}
    &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -3px;
        left: 0;
        background-color: #000;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
    }

    &:hover, &.active {
        text-decoration: none;
        color: #000;
        &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
            background-color: #000;
        }
    }
    @include media-breakpoint-down(md) {
        cursor: pointer !important;
        span {
            cursor: pointer !important;
        }
    }
}

ul{
    margin: 0;
    padding: 0;
    
    li{
        list-style-type: none;
    }
}