/*
|--------------------
|     Carrier - Header
|--------------------
*/
#page-carrier.top-bk-with-breadcrumb:before {
    height: 600px;
}

.carrier-header {
    margin-bottom: 160px;
    position: relative;
    .bloc-title {
        margin-bottom: 75px;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 245px;
        height: 230px;
        background: url('../img/global/bk-moriss-gray.svg');
        background-size: contain;
        z-index: -1;
    }
}

/*
|--------------------
|     Carrier - Présentation
|--------------------
*/
.carrier-presentation {
    padding: 100px 0;
    position: relative;
    margin-bottom: 125px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 60%;
        height: 100%;
        background: #F7F5F0;
        z-index: -1;
    }
    .description {
        max-width: 400px;
        margin-bottom: 30px;
    }
    &-strength {
        &-title {
            margin-top: 40px;
            margin-bottom: 40px;
            font-family: 'adobegaramond';
            font-size: $font-size-20;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        background: #F7F5F0;
        padding: 50px 10px;
        text-align: center;
        &:before { display: none; }
        .bloc-title { margin-top: 30px }
        .description { margin: 0 auto 30px }
        .buttons { margin-bottom: 30px;}
        &-strength {
            &-title {
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
    }
}

/*
|--------------------
|     Carrier - Rejoignez-nous
|--------------------
*/
.carrier-join {
    background: #F7F5F0;
    margin-bottom: 120px;
    @include media-breakpoint-down(sm) {
        padding: 50px 10px;
    }
}