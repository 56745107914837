/*
|--------------------
|       Contact
|--------------------
*/

#page-contact{
    /*
    |
    | Section contact
    |------------------
    */
    .section-map{
        #map {
            position: relative;
            width: 100%;
            height: 50vh;
            background-color: $black;
        }
    }   
}

/*
|--------------------
|       Confirmation
|--------------------
*/
#page-confirmation {
    .sale-estimate-result {
        margin-bottom: $size-100;
        &-text {
            font-size: $font-size-28;
            .bloc-buttons {
                margin-top: $size-50;
            }
            &-number {
                font-weight: 700;
            }
        }
    }
}

.contact {
    margin-bottom: 150px;
}