@charset "UTF-8";
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Bootstrap lightweight */
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --black: #000000;
  --very-dark-grey: #3A3A3A;
  --dark-grey: #424242;
  --grey: #999;
  --light-grey: #dedede;
  --very-light-grey: #fafafa;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3A3A3A;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1300px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 0; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #FFFFFF; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #FFFFFF;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.75rem;
  line-height: 1.4; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.2; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.55rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(3.45rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.4;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .contact-form input[type="submit"] {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn, .contact-form input[type="submit"] {
      transition: none; } }
  .btn:hover, .contact-form input:hover[type="submit"], .btn:focus, .contact-form input:focus[type="submit"] {
    text-decoration: none; }
  .btn:focus, .contact-form input:focus[type="submit"], .btn.focus, .contact-form input.focus[type="submit"] {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .contact-form input.disabled[type="submit"], .btn:disabled, .contact-form input:disabled[type="submit"] {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .contact-form input:not(:disabled):not(.disabled)[type="submit"] {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary.nice-select.open:not(:disabled):not(.disabled), .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #FFFFFF;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #FFFFFF;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary.nice-select.open:not(:disabled):not(.disabled), .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success.nice-select.open:not(:disabled):not(.disabled), .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success.nice-select.open:not(:disabled):not(.disabled):focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #FFFFFF;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info.nice-select.open:not(:disabled):not(.disabled), .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info.nice-select.open:not(:disabled):not(.disabled):focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning.nice-select.open:not(:disabled):not(.disabled), .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning.nice-select.open:not(:disabled):not(.disabled):focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger.nice-select.open:not(:disabled):not(.disabled), .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger.nice-select.open:not(:disabled):not(.disabled):focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light.nice-select.open:not(:disabled):not(.disabled), .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light.nice-select.open:not(:disabled):not(.disabled):focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark.nice-select.open:not(:disabled):not(.disabled), .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark.nice-select.open:not(:disabled):not(.disabled):focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary.nice-select.open:not(:disabled):not(.disabled), .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary.nice-select.open:not(:disabled):not(.disabled), .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success.nice-select.open:not(:disabled):not(.disabled), .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info.nice-select.open:not(:disabled):not(.disabled), .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning.nice-select.open:not(:disabled):not(.disabled), .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger.nice-select.open:not(:disabled):not(.disabled), .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light.nice-select.open:not(:disabled):not(.disabled), .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark.nice-select.open:not(:disabled):not(.disabled), .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark.nice-select.open:not(:disabled):not(.disabled):focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .contact-form .btn-group-lg > input[type="submit"] {
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.4;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .contact-form .btn-group-sm > input[type="submit"] {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .contact-form .btn-group > input[type="submit"],
  .btn-group-vertical > .btn,
  .contact-form .btn-group-vertical > input[type="submit"] {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover, .contact-form .btn-group > input:hover[type="submit"],
    .btn-group-vertical > .btn:hover,
    .contact-form .btn-group-vertical > input:hover[type="submit"] {
      z-index: 1; }
    .btn-group > .btn:focus, .contact-form .btn-group > input:focus[type="submit"], .btn-group > .btn:active, .btn-group > .btn.nice-select.open, .contact-form .btn-group > input.nice-select.open[type="submit"], .contact-form .btn-group > input:active[type="submit"], .btn-group > .btn.active, .contact-form .btn-group > input.active[type="submit"],
    .btn-group-vertical > .btn:focus,
    .contact-form .btn-group-vertical > input:focus[type="submit"],
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.nice-select.open,
    .contact-form .btn-group-vertical > input.nice-select.open[type="submit"],
    .contact-form .btn-group-vertical > input:active[type="submit"],
    .btn-group-vertical > .btn.active,
    .contact-form .btn-group-vertical > input.active[type="submit"] {
      z-index: 1; }
  .btn-group .btn + .btn, .btn-group .contact-form input[type="submit"] + .btn, .contact-form .btn-group input[type="submit"] + .btn, .btn-group .contact-form .btn + input[type="submit"], .contact-form .btn-group .btn + input[type="submit"], .btn-group .contact-form input[type="submit"] + input[type="submit"], .contact-form .btn-group input[type="submit"] + input[type="submit"],
  .btn-group .btn + .btn-group,
  .btn-group .contact-form input[type="submit"] + .btn-group,
  .contact-form .btn-group input[type="submit"] + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .contact-form .btn-group + input[type="submit"],
  .contact-form .btn-group .btn-group + input[type="submit"],
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .contact-form input[type="submit"] + .btn,
  .contact-form .btn-group-vertical input[type="submit"] + .btn,
  .btn-group-vertical .contact-form .btn + input[type="submit"],
  .contact-form .btn-group-vertical .btn + input[type="submit"],
  .btn-group-vertical .contact-form input[type="submit"] + input[type="submit"],
  .contact-form .btn-group-vertical input[type="submit"] + input[type="submit"],
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .contact-form input[type="submit"] + .btn-group,
  .contact-form .btn-group-vertical input[type="submit"] + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .contact-form .btn-group + input[type="submit"],
  .contact-form .btn-group-vertical .btn-group + input[type="submit"],
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child, .contact-form .btn-group > input:first-child[type="submit"] {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .contact-form .btn-group > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
.btn-group > .btn-group:not(:last-child) > .btn,
.contact-form .btn-group > .btn-group:not(:last-child) > input[type="submit"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .contact-form .btn-group > input:not(:first-child)[type="submit"],
.btn-group > .btn-group:not(:first-child) > .btn,
.contact-form .btn-group > .btn-group:not(:first-child) > input[type="submit"] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .contact-form .btn-group-sm > input[type="submit"] + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .contact-form .btn-group-lg > input[type="submit"] + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .contact-form input[type="submit"], .contact-form .btn-group-vertical input[type="submit"],
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .contact-form .btn-group-vertical > input[type="submit"] + .btn, .contact-form .btn-group-vertical > .btn + input[type="submit"], .contact-form .btn-group-vertical > input[type="submit"] + input[type="submit"],
  .btn-group-vertical > .btn + .btn-group,
  .contact-form .btn-group-vertical > input[type="submit"] + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .contact-form .btn-group-vertical > .btn-group + input[type="submit"],
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .contact-form .btn-group-vertical > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .contact-form .btn-group-vertical > .btn-group:not(:last-child) > input[type="submit"] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .contact-form .btn-group-vertical > input:not(:first-child)[type="submit"],
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .contact-form .btn-group-vertical > .btn-group:not(:first-child) > input[type="submit"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .contact-form .btn-group-toggle > input[type="submit"],
.btn-group-toggle > .btn-group > .btn,
.contact-form .btn-group-toggle > .btn-group > input[type="submit"] {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .contact-form .btn-group-toggle > input[type="submit"] input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .contact-form .btn-group-toggle > input[type="submit"] input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .contact-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .contact-form .btn-group-toggle > .btn-group > input[type="submit"] input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .contact-form input[type="submit"], .contact-form .input-group-prepend input[type="submit"],
  .input-group-append .btn,
  .input-group-append .contact-form input[type="submit"],
  .contact-form .input-group-append input[type="submit"] {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn, .input-group-prepend .contact-form input[type="submit"] + .btn, .contact-form .input-group-prepend input[type="submit"] + .btn, .input-group-prepend .contact-form .btn + input[type="submit"], .contact-form .input-group-prepend .btn + input[type="submit"], .input-group-prepend .contact-form input[type="submit"] + input[type="submit"], .contact-form .input-group-prepend input[type="submit"] + input[type="submit"],
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .contact-form input[type="submit"] + .input-group-text,
  .contact-form .input-group-prepend input[type="submit"] + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .contact-form .input-group-text + input[type="submit"],
  .contact-form .input-group-prepend .input-group-text + input[type="submit"],
  .input-group-append .btn + .btn,
  .input-group-append .contact-form input[type="submit"] + .btn,
  .contact-form .input-group-append input[type="submit"] + .btn,
  .input-group-append .contact-form .btn + input[type="submit"],
  .contact-form .input-group-append .btn + input[type="submit"],
  .input-group-append .contact-form input[type="submit"] + input[type="submit"],
  .contact-form .input-group-append input[type="submit"] + input[type="submit"],
  .input-group-append .btn + .input-group-text,
  .input-group-append .contact-form input[type="submit"] + .input-group-text,
  .contact-form .input-group-append input[type="submit"] + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .contact-form .input-group-text + input[type="submit"],
  .contact-form .input-group-append .input-group-text + input[type="submit"] {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.contact-form .input-group-lg > .input-group-prepend > input[type="submit"],
.input-group-lg > .input-group-append > .btn,
.contact-form .input-group-lg > .input-group-append > input[type="submit"] {
  height: calc(3.45rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.4;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.contact-form .input-group-sm > .input-group-prepend > input[type="submit"],
.input-group-sm > .input-group-append > .btn,
.contact-form .input-group-sm > .input-group-append > input[type="submit"] {
  height: calc(1.55rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn, .contact-form .input-group > .input-group-prepend > input[type="submit"],
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.contact-form .input-group > .input-group-append:not(:last-child) > input[type="submit"],
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.contact-form .input-group > .input-group-append:last-child > input:not(:last-child):not(.dropdown-toggle)[type="submit"],
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .contact-form .input-group > .input-group-append > input[type="submit"],
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.contact-form .input-group > .input-group-prepend:not(:first-child) > input[type="submit"],
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.contact-form .input-group > .input-group-prepend:first-child > input:not(:first-child)[type="submit"],
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before, .custom-control-input.nice-select.open ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFFFFF' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.55rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(3.45rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #80bdff; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active, .custom-range.nice-select.open::-webkit-slider-thumb {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active, .custom-range.nice-select.open::-moz-range-thumb {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active, .custom-range.nice-select.open::-ms-thumb {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000000;
      text-decoration: none;
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #999 !important; }

.border-top {
  border-top: 1px solid #999 !important; }

.border-right {
  border-right: 1px solid #999 !important; }

.border-bottom {
  border-bottom: 1px solid #999 !important; }

.border-left {
  border-left: 1px solid #999 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.img-border {
  border: 1.25rem solid #F7F5F0; }

.img-border-white {
  border: 3.375rem solid #fff; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.shadow-sm {
  box-shadow: -1px 20px 42px -45px rgba(0, 0, 0, 0.75) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: 'avenir';
  src: url("../fonts/avenir/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir/Avenir-Light.otf") format("opentype"), url("../fonts/avenir/Avenir-Light.woff") format("woff"), url("../fonts/avenir/Avenir-Light.ttf") format("truetype"), url("../fonts/avenir/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'avenir';
  src: url("../fonts/avenir/Avenir-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir/Avenir-Regular.otf") format("opentype"), url("../fonts/avenir/Avenir-Regular.woff") format("woff"), url("../fonts/avenir/Avenir-Regular.ttf") format("truetype"), url("../fonts/avenir/Avenir-Regular.svg#Avenir-Regular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'avenir';
  src: url("../fonts/avenir/Avenir-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir/Avenir-Bold.otf") format("opentype"), url("../fonts/avenir/Avenir-Bold.woff") format("woff"), url("../fonts/avenir/Avenir-Bold.ttf") format("truetype"), url("../fonts/avenir/Avenir-Bold.svg#Avenir-Bold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'adobegaramond';
  src: url("../fonts/adobegaramond/AdobeGaramond.eot?#iefix") format("embedded-opentype"), url("../fonts/adobegaramond/AdobeGaramond.otf") format("opentype"), url("../fonts/adobegaramond/AdobeGaramond.woff") format("woff"), url("../fonts/adobegaramond/AdobeGaramond.woff2") format("woff2"), url("../fonts/adobegaramond/AdobeGaramond.ttf") format("truetype"), url("../fonts/adobegaramond/AdobeGaramond.svg#AdobeGaramond") format("svg");
  font-weight: 400;
  font-style: normal; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  overflow-x: hidden; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "avenir", sans-serif;
  color: #3A3A3A;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: 0.5px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  padding: 30px;
  background: #f7f5f0; }
  @media (max-width: 991.98px) {
    body {
      padding: 10px; } }

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-family: "avenir", sans-serif;
  font-weight: 400;
  line-height: 1.2; }

button:focus, input:focus, textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: inherit; }

a, img, span, button {
  display: inline-block; }

a {
  position: relative;
  transition: all 0.25s; }
  a:active, a.nice-select.open, a:focus {
    outline: none;
    box-shadow: none !important;
    color: #000; }
  a:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s; }
  a:hover, a.active {
    text-decoration: none;
    color: #000; }
    a:hover:after, a.active:after {
      transform-origin: bottom left;
      transform: scaleX(1);
      background-color: #000; }
  @media (max-width: 991.98px) {
    a {
      cursor: pointer !important; }
      a span {
        cursor: pointer !important; } }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

/*
|--------------------
|      HEADER
|--------------------
*/
/*
|
| Header
|---------
*/
.link-menu, #header .item-menu li .item-link, #footer .lang-container {
  color: #3A3A3A;
  font-size: 1.25rem;
  font-weight: 400; }

#header-sticky-wrapper {
  z-index: 3;
  position: absolute;
  top: 30px;
  width: calc(100% - 60px); }
  @media (max-width: 991.98px) {
    #header-sticky-wrapper {
      width: calc(100% - 20px); } }

#header {
  width: 100%;
  padding: 30px; }
  #header .item-logo svg path, #header .item-logo svg polygon {
    fill: #FFFFFF; }
  #header .item-menu li:not(:last-child) {
    margin-right: 20px; }
    @media (max-width: 1199.98px) {
      #header .item-menu li:not(:last-child) {
        margin-right: 10px; } }
  @media (max-width: 1599.98px) {
    #header .item-menu li .item-link {
      font-size: 0.875rem; } }
  #header .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 10;
    padding: 5px;
    position: relative;
    border: 1px solid transparent; }
    #header .btn-menu > .item-burger {
      display: block;
      width: 20px; }
      #header .btn-menu > .item-burger > span {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #3A3A3A;
        border-radius: 2px; }
        #header .btn-menu > .item-burger > span:nth-child(2) {
          margin: 4px 0; }
    #header .btn-menu > .btn-menu-title {
      margin-left: 10px; }
  #header .menu-left .menu-item:first-child {
    z-index: 10; }
    #header .menu-left .menu-item:first-child a {
      font-weight: 700; }
  #header .menu-center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0); }
    @media (max-width: 991.98px) {
      #header .menu-center .item-logo svg {
        width: 80px;
        height: auto; } }
  #header .menu-right .menu-item:first-child .item-link, #header .menu-right .menu-item:first-child .btn-menu-title, #header .menu-right .menu-item:nth-child(2) .item-link, #header .menu-right .menu-item:nth-child(2) .btn-menu-title, #header .menu-right .menu-item:nth-child(3) .item-link, #header .menu-right .menu-item:nth-child(3) .btn-menu-title, #header .menu-right .menu-item:nth-child(4) .item-link, #header .menu-right .menu-item:nth-child(4) .btn-menu-title, #header .menu-right .menu-item:last-child .item-link, #header .menu-right .menu-item:last-child .btn-menu-title {
    font-weight: 700; }
  @media (max-width: 1199.98px) {
    #header {
      padding: 10px 10px 0; } }
  @media (max-width: 991.98px) {
    #header .item-menu li:not(:last-child) {
      margin-right: 0; } }

@media (max-width: 991.98px) {
  #header .menu-item-384, #header .menu-item-386, #header .menu-item-387, #header .menu-item-388, #header .menu-item-2696,
  #header .menu-item-521, #header .menu-item-522, #header .menu-item-523, #header .menu-item-524, #header .menu-item-74470, #header .menu-item-74469 {
    display: none; } }

@media (max-width: 767.98px) {
  #header .menu-left .item-menu, #header .btn-menu-title {
    display: none !important; } }

.is-sticky #header {
  padding-bottom: 0;
  height: 0; }

.is-sticky .menu-item-384, .is-sticky .menu-item-386, .is-sticky .menu-item-387, .is-sticky .menu-item-388, .is-sticky .menu-item-2696, .is-sticky .menu-center,
.is-sticky .menu-item-521, .is-sticky .menu-item-522, .is-sticky .menu-item-523, .is-sticky .menu-item-524, .is-sticky .menu-item-74470, .is-sticky .menu-item-74469 {
  display: none; }

.is-sticky .menu-left .menu-item:first-child .btn, .is-sticky .menu-left .menu-item:first-child .contact-form input[type="submit"], .contact-form .is-sticky .menu-left .menu-item:first-child input[type="submit"] {
  background: #fff; }

.is-sticky .btn-menu {
  border: 1px solid #000 !important;
  background: #fff !important; }

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  overflow: auto;
  display: none; }
  #mobile-menu .item-container ul > li a {
    color: #3A3A3A;
    font-size: 2vw;
    font-weight: 700;
    line-height: 1.8;
    -webkit-font-smoothing: antialiased;
    padding: 0 1rem;
    text-decoration: none;
    position: relative; }
    #mobile-menu .item-container ul > li a:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 10px;
      left: 0;
      background-color: #000;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s; }
    #mobile-menu .item-container ul > li a:hover:after {
      transform-origin: bottom left;
      transform: scaleX(1);
      background-color: #000; }
    @media (max-width: 575.98px) {
      #mobile-menu .item-container ul > li a {
        font-size: 26px; } }
  #mobile-menu .item-container ul > li:last-child a {
    color: #EAE6DB;
    border: 1px solid #EAE6DB; }
    #mobile-menu .item-container ul > li:last-child a:before, #mobile-menu .item-container ul > li:last-child a:after {
      display: none; }
  @media (max-width: 767.98px) {
    #mobile-menu .item-container ul > li a:after {
      bottom: 5px !important; } }

/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding-top: 120px; }
  #footer .footer-content {
    background: #F7F5F0;
    padding: 0 30px 30px; }
    #footer .footer-content .estimate-image {
      text-align: center;
      position: relative;
      top: -90px; }
      #footer .footer-content .estimate-image-link {
        display: inline-block; }
        #footer .footer-content .estimate-image-link-title {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          text-decoration: underline;
          font-size: 4rem;
          font-weight: 700; }
          @media (max-width: 991.98px) {
            #footer .footer-content .estimate-image-link-title {
              font-size: 2rem; } }
    #footer .footer-content .presentation-description {
      margin-top: 20px; }
    #footer .footer-content .footer-menu li {
      width: 230px;
      margin-bottom: 10px; }
      #footer .footer-content .footer-menu li a {
        font-size: 1.5rem;
        line-height: 2;
        font-weight: 700; }
    #footer .footer-content .copyright {
      font-size: 0.75rem;
      padding-top: 11px; }
    @media (max-width: 1199.98px) {
      #footer .footer-content .footer-menu li {
        width: 180px; }
        #footer .footer-content .footer-menu li a {
          font-size: 1.125rem; } }
    @media (max-width: 991.98px) {
      #footer .footer-content {
        text-align: center;
        justify-content: center !important; }
        #footer .footer-content .presentation-description {
          display: none; }
        #footer .footer-content .footer-menu {
          margin-top: 30px; }
          #footer .footer-content .footer-menu li {
            width: 33.33%; }
        #footer .footer-content .social-languages {
          justify-content: center !important; } }
    @media (max-width: 767.98px) {
      #footer .footer-content .footer-menu li {
        width: 50%; }
      #footer .footer-content .estimate-image {
        position: static;
        margin-bottom: 30px;
        padding-top: 30px; }
        #footer .footer-content .estimate-image-link-title {
          font-size: 1.125rem; } }
  #footer .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    cursor: pointer; }
    #footer .lang-container ul {
      position: absolute;
      z-index: 1;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1), transform 2s cubic-bezier(0.19, 1, 0.22, 1); }
      @media (max-width: 991.98px) {
        #footer .lang-container ul {
          position: static;
          opacity: 1;
          visibility: visible;
          transform: translateX(0) !important; } }
    #footer .lang-container .lang-item, #footer .lang-container .item-lang {
      padding: 10px; }
    #footer .lang-container:hover ul {
      opacity: 1 !important;
      visibility: visible !important;
      transform: translateX(0) !important; }
  #footer .social a {
    opacity: 0.6; }
  #footer .newsletter-field {
    padding: 0; }
    #footer .newsletter-field .gfield_description {
      font-size: 1.125rem;
      font-weight: 700; }
    #footer .newsletter-field .validation_message {
      font-size: 0.75rem;
      font-weight: 700; }
    #footer .newsletter-field input {
      margin: 0;
      padding: 10px;
      border: 1px solid #aaa;
      line-height: normal; }
  #footer .newsletter .gform_body {
    margin-bottom: 10px; }
  #footer .newsletter .gform_footer {
    margin-top: 0;
    padding: 0;
    text-align: left; }
    #footer .newsletter .gform_footer .btn span, #footer .newsletter .gform_footer .contact-form input[type="submit"] span, .contact-form #footer .newsletter .gform_footer input[type="submit"] span {
      font-size: 0.75rem; }
  #footer .newsletter .gform_confirmation_message {
    color: green;
    margin-top: 40px;
    font-weight: 700; }
  @media (max-width: 991.98px) {
    #footer .newsletter {
      text-align: center; }
      #footer .newsletter-field .gfield_description {
        text-align: center; }
      #footer .newsletter-field input {
        text-align: center; }
      #footer .newsletter .gform_footer {
        text-align: center; } }
  @media (max-width: 767.98px) {
    #footer {
      padding: 0; } }

/*
|--------------------
|      CONTENT
|--------------------
*/
/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 0 !important;
  background: #ccc; }
  .cookie-banner-accept {
    margin-top: 10px; }
    .cookie-banner-accept a.btn {
      font-size: 0.75rem; }
  .cookie-banner .item-close {
    font-size: 24px;
    cursor: pointer; }
  .cookie-banner p {
    margin: 0; }
  @media (max-width: 767.98px) {
    .cookie-banner {
      padding: 20px 0;
      font-size: 12px; } }
  @media (max-width: 575.98px) {
    .cookie-banner {
      padding: 10px 0 10px 0; }
      .cookie-banner .item-close {
        font-size: 18px; } }

/*
|
| Pagination
|---------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #999; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #999; }

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 76px; }
  @media (max-width: 1199.98px) {
    .page-offset {
      padding-top: 106px; } }

.bloc-title {
  margin-bottom: 30px; }

.title {
  font-size: 3.125rem;
  font-weight: 700; }
  @media (max-width: 1599.98px) {
    .title {
      font-size: 2.375rem; } }
  @media (max-width: 991.98px) {
    .title {
      font-size: 1.75rem; } }
  @media (max-width: 767.98px) {
    .title {
      text-align: center; } }

.subtitle {
  font-size: 1.25rem;
  font-family: adobegaramond; }
  @media (max-width: 991.98px) {
    .subtitle {
      font-size: 0.875rem; } }
  @media (max-width: 767.98px) {
    .subtitle {
      text-align: center; } }

.description {
  margin-bottom: 125px; }
  .description h2 {
    font-weight: 700;
    font-size: 1.125rem;
    margin: 1.25rem 0; }
  .description h3 {
    font-weight: 700;
    font-size: 1rem;
    margin: 1.25rem 0; }
  .description h4 {
    font-weight: 700;
    font-size: 0.875rem;
    margin: 1.25rem 0; }
  .description ul {
    margin: 20px 0; }
    .description ul li {
      padding: 0 0 5px 40px;
      position: relative; }
      .description ul li:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 20px;
        background: #000;
        width: 4px;
        height: 4px; }
  .description strong {
    font-weight: 700; }

.top-bk {
  padding-top: 165px; }
  @media (max-width: 767.98px) {
    .top-bk {
      padding-top: 120px; } }

.top-bk-with-breadcrumb {
  padding-top: 165px; }
  .top-bk-with-breadcrumb:before {
    content: '';
    position: absolute;
    top: 30px;
    left: 30px;
    width: calc(100% - 60px);
    height: 770px;
    background: #F7F5F0;
    z-index: -1; }
  @media (max-width: 991.98px) {
    .top-bk-with-breadcrumb {
      padding-top: 120px; }
      .top-bk-with-breadcrumb:before {
        top: 10px;
        left: 10px;
        width: calc(100% - 20px); } }

.site-container {
  background: #f7f5f0; }
  .site-container .splashscreen-right-line {
    position: relative;
    z-index: 2; }

.top-bk-with-breadcrumb-full {
  padding-top: 138px;
  background: #F7F5F0;
  position: relative; }
  .top-bk-with-breadcrumb-full:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 300px;
    background: #F7F5F0;
    z-index: -1; }

.breadcrumbs {
  margin-bottom: 60px;
  position: relative;
  font-family: 'adobegaramond'; }
  .breadcrumbs a {
    font-family: 'adobegaramond';
    color: #7e7e7e; }
  @media (max-width: 991.98px) {
    .breadcrumbs {
      margin-bottom: 30px; } }

.overflow-hidden {
  overflow: hidden; }

a.no-hover:hover:after {
  display: none; }

a.img-hover-link img {
  transition: all 0.5s; }

a.img-hover-link:hover img {
  transform: scale(1.1) !important; }

.map {
  position: relative;
  display: inline-block; }
  .map .marker {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    transition: all 0.25s; }
    .map .marker:hover {
      transform: scale(1.5); }
    .map .marker.active {
      transform: scale(1.5); }

.grid-layout {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  grid-auto-flow: dense;
  margin-bottom: 30px; }
  .grid-layout .grid-item {
    position: relative;
    overflow: hidden; }
    .grid-layout .grid-item-name {
      opacity: 0;
      position: absolute;
      background: #000;
      color: #fff;
      padding: 5px 50px;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      transition: all 0.5s; }
    .grid-layout .grid-item:hover .grid-item-name {
      opacity: 1; }
  .grid-layout .w-2 {
    grid-column-end: span 2;
    grid-row-end: span 1;
    height: 249px; }
    .grid-layout .w-2 img {
      transform: translate(0, -50px); }
  .grid-layout .h-2 {
    grid-column-end: span 1;
    grid-row-end: span 2;
    height: 521px; }
    .grid-layout .h-2 img {
      width: auto;
      height: 100%;
      left: 50%;
      transform: translate(-50%, 0); }
  .grid-layout .sq-2 {
    grid-column-end: span 2;
    grid-row-end: span 2; }
    .grid-layout .sq-2 img {
      max-width: 100%;
      object-fit: cover;
      position: relative; }
  @media (max-width: 991.98px) {
    .grid-layout {
      grid-template-columns: repeat(4, 1fr); } }
  @media (max-width: 767.98px) {
    .grid-layout {
      display: block; }
      .grid-layout .grid-item {
        margin-bottom: 10px; }
        .grid-layout .grid-item img {
          position: static;
          max-width: 100%;
          width: auto;
          height: auto;
          transform: translate(0, 0); }
      .grid-layout .w-2 {
        height: auto; }
      .grid-layout .h-2 {
        height: auto; }
      .grid-layout .sq-2 {
        height: auto; } }

.cms {
  margin-bottom: 120px; }

#back-to-top {
  display: inline-block;
  border: 1px solid #000;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  position: fixed;
  bottom: 60px;
  left: 120px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 8; }
  #back-to-top:after {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: '';
    display: block;
    height: 10px;
    margin-top: -6px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 10px;
    transform-origin: 66% 66%;
    transform: rotate(-135deg); }
  #back-to-top:hover, #back-to-top:active, #back-to-top.nice-select.open {
    background-color: #D4D2CE; }
  #back-to-top.show {
    opacity: 1;
    visibility: visible; }
  @media (max-width: 991.98px) {
    #back-to-top {
      bottom: 20px;
      right: 20px; } }

@media (max-width: 767.98px) {
  section, .products-images-line {
    margin-bottom: 2rem !important; }
  .products-line:last-child .products-images-line {
    margin-bottom: 0 !important; } }

.review-box {
  margin-bottom: 30px; }
  .review-box-quotation {
    background: #F7F5F0;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    height: 200px; }
    .review-box-quotation-google {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px; }
      .review-box-quotation-google a {
        display: block;
        height: 100%; }
    .review-box-quotation-text {
      position: relative;
      padding-left: 30px; }
      .review-box-quotation-text:before {
        content: '';
        background: url("../img/icon/quotation.svg");
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px; }
    .review-box-quotation-stars {
      margin-bottom: 20px; }
    .review-box-quotation-informations-picture {
      margin-right: 20px; }
    .review-box-quotation-informations-text-name {
      font-weight: 700; }
    .review-box-quotation-informations-text-time {
      font-size: 0.75rem;
      color: #aaa; }

.review .bloc-buttons {
  margin-top: 30px; }

@media (max-width: 991.98px) {
  .review-box-quotation {
    height: auto; } }

.alert-custom {
  border: 1px solid #eeece7;
  border-radius: 0; }

.referencement-menu-wrap-link {
  display: list-item;
  margin-bottom: 5px; }

.filter-mobile {
  display: none;
  overflow: visible;
  width: 300px;
  margin: 0 auto 30px; }

@media (max-width: 991.98px) {
  .filter-desktop {
    display: none !important; }
  .filter-mobile {
    display: block;
    margin: auto;
    text-align: center; }
  .team-images-content {
    margin-top: 30px; }
  .team-images-filters {
    margin-bottom: 30px !important; } }

.nice-select {
  background: transparent !important; }

.map-biens {
  text-align: center; }
  .map-biens svg {
    max-width: 100%;
    height: auto; }
  .map-biens .path:hover polygon, .map-biens .path:hover path, .map-biens .path.active path, .map-biens .path.active polygon {
    transition: all 0.25s;
    fill: #C4C3C3;
    cursor: pointer; }
  .map-biens .path:hover text {
    cursor: pointer; }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
/*
|--------------------
|     SPLASHSCREEN
|--------------------
*/
.splashscreen {
  position: relative;
  margin-bottom: 7rem; }
  .splashscreen-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .splashscreen-wrapper .splashscreen-left {
      padding: 30px;
      z-index: 2; }
      .splashscreen-wrapper .splashscreen-left h1 {
        font-size: 3.875rem;
        font-weight: 700; }
      .splashscreen-wrapper .splashscreen-left h2 {
        font-size: 1.875rem;
        font-family: adobegaramond; }
      .splashscreen-wrapper .splashscreen-left-style {
        display: none;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 500px;
        height: 400px;
        background: #F7F5F0;
        z-index: -1; }
      @media (max-width: 1599.98px) {
        .splashscreen-wrapper .splashscreen-left h1 {
          font-size: 2.75rem; }
        .splashscreen-wrapper .splashscreen-left h2 {
          font-size: 1.125rem; }
        .splashscreen-wrapper .splashscreen-left-style {
          height: 200px; } }
      @media (max-width: 767.98px) {
        .splashscreen-wrapper .splashscreen-left h1 {
          font-size: 1.5rem; }
        .splashscreen-wrapper .splashscreen-left h2 {
          font-size: 1rem; } }
    .splashscreen-wrapper .splashscreen-right {
      padding: 30px 90px 30px;
      position: relative; }
      .splashscreen-wrapper .splashscreen-right-line a {
        text-align: center; }
      .splashscreen-wrapper .splashscreen-right-line-first {
        margin-bottom: 1.25rem; }
      .splashscreen-wrapper .splashscreen-right-style {
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translate(0, -50%);
        width: 458px;
        height: 430px;
        z-index: 1;
        background: url("../img/global/bk-moriss-gray.svg");
        background-size: contain; }
      @media (max-width: 1599.98px) {
        .splashscreen-wrapper .splashscreen-right {
          padding: 10px 30px 10px; }
          .splashscreen-wrapper .splashscreen-right-style {
            width: 213px;
            height: 200px; } }
      @media (max-width: 767.98px) {
        .splashscreen-wrapper .splashscreen-right {
          padding: 50px 10px 10px; }
          .splashscreen-wrapper .splashscreen-right-style {
            display: none; } }
  .splashscreen .splashscreen-movie iframe {
    width: 100%;
    height: 874px;
    position: relative;
    z-index: 2; }
  .splashscreen .splashscreen-movie:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent; }
  @media (max-width: 991.98px) {
    .splashscreen .splashscreen-movie {
      padding-top: 120px; }
      .splashscreen .splashscreen-movie iframe {
        height: 490px; } }
  @media (max-width: 767.98px) {
    .splashscreen-wrapper {
      position: static;
      display: block;
      text-align: center; }
      .splashscreen-wrapper .splashscreen-left-style {
        display: none; }
      .splashscreen-wrapper .splashscreen-right-style {
        right: auto;
        top: 50%;
        left: 50%; }
    .splashscreen .splashscreen-movie iframe {
      height: 365px; } }
  @media (max-width: 575.98px) {
    .splashscreen .splashscreen-movie iframe {
      height: 280px; } }

[data-aos^=fade][data-aos^=fade].aos-animate.splashscreen-left-style {
  transform: translate(0, -50%) !important; }

[data-aos^=fade][data-aos^=fade].aos-animate.splashscreen-right-style {
  transform: translate(0, -50%) !important; }
  @media (max-width: 767.98px) {
    [data-aos^=fade][data-aos^=fade].aos-animate.splashscreen-right-style {
      transform: translate(-50%, -50%) !important; } }

@media (max-width: 767.98px) {
  .splashscreen-wrapper [data-aos^=fade][data-aos^=fade] {
    opacity: 1 !important;
    transform: translateZ(0) !important;
    transition: none; } }

/*
|--------------------
|     ESTIMER MON BIEN
|--------------------
*/
.estimate {
  margin-bottom: 7rem; }
  .estimate-content {
    padding: 60px;
    border: 1px solid #000; }
  .estimate .container {
    position: relative; }
  @media (max-width: 767.98px) {
    .estimate {
      padding: 30px 0; }
      .estimate-content {
        padding: 30px 10px;
        border: 1px solid #000; } }

/*
|--------------------
|     MORISS IMMOBILIER
|--------------------
*/
.moriss-immobilier {
  margin-bottom: 9.375rem;
  padding: 60px;
  position: relative; }
  .moriss-immobilier-description {
    max-width: 220px;
    margin: 0 0 30px; }
    @media (max-width: 767.98px) {
      .moriss-immobilier-description {
        max-width: none;
        text-align: center; } }
  .moriss-immobilier:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    background: #F7F5F0;
    z-index: -1; }
    @media (max-width: 767.98px) {
      .moriss-immobilier:before {
        width: 100%; } }
  .moriss-immobilier-wrap-image {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative; }
    .moriss-immobilier-wrap-image img {
      max-width: none; }
    .moriss-immobilier-wrap-image:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-60%, -50%);
      width: 458px;
      height: 430px;
      background: url("../img/global/bk-moriss-gray.svg");
      background-size: contain;
      z-index: -1; }
  .moriss-immobilier-right {
    padding: 0 0 0 160px; }
    @media (max-width: 1599.98px) {
      .moriss-immobilier-right {
        padding: 0 0 0 50px; } }
    @media (max-width: 767.98px) {
      .moriss-immobilier-right {
        padding: 0; } }
  @media (max-width: 767.98px) {
    .moriss-immobilier {
      padding: 60px 10px; }
      .moriss-immobilier .buttons {
        text-align: center; } }

/*
|--------------------
|     LES BIENS
|--------------------
*/
.products {
  margin-bottom: 9.375rem; }
  .products-bloc {
    padding: 0 20px;
    height: 100%; }
  .products .bloc-title {
    margin-bottom: 7rem;
    position: relative; }
    .products .bloc-title .btn, .products .bloc-title .contact-form input[type="submit"], .contact-form .products .bloc-title input[type="submit"] {
      position: absolute;
      top: 6px;
      right: 0; }
      @media (max-width: 767.98px) {
        .products .bloc-title .btn, .products .bloc-title .contact-form input[type="submit"], .contact-form .products .bloc-title input[type="submit"] {
          display: none; } }
  .products-images-line-link-wrap {
    position: relative; }
    .products-images-line-link-wrap-view {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 4rem;
      font-weight: 700;
      text-decoration: underline; }
      @media (max-width: 1599.98px) {
        .products-images-line-link-wrap-view {
          font-size: 2rem; } }
  .products-images-line-link-wrap-3 {
    padding-left: 10px; }
  .products-content {
    margin-top: -50px;
    margin-bottom: 50px; }
  .products-line {
    margin-top: 50px; }
    .products-line-image {
      margin-bottom: 10px;
      position: relative; }
      .products-line-image-status {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #f7f5f0;
        color: #000;
        padding: 10px 20px;
        font-size: 1.25rem; }
        .products-line-image-status.product-sous-offre, .products-line-image-status.product-sous-promesse, .products-line-image-status.product-vendu {
          background: #000;
          color: #f7f5f0; }
  .products-bloc-title {
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #333; }
  .products-bloc-subtitle {
    font-size: 1rem;
    margin-bottom: 10px; }
    .products-bloc-subtitle-agency {
      color: #9f9e9e; }
  .products-bloc-price {
    font-size: 1.375rem; }
  @media (max-width: 991.98px) {
    .products-bloc {
      display: block !important;
      margin: 30px 0;
      text-align: center !important; } }
  .products-link {
    text-align: center;
    margin-top: 50px; }
  @media (max-width: 767.98px) {
    .products .bloc-title {
      margin-bottom: 2rem; } }

/*
|--------------------
|     LES AGENCES
|--------------------
*/
.agencies {
  margin-bottom: 7rem;
  padding: 100px;
  border: 1px solid #000; }
  .agencies .bloc-description {
    max-width: 200px;
    margin-bottom: 40px; }
  .agencies-list-title li {
    padding: 5px 0; }
    .agencies-list-title li.active h3 a, .agencies-list-title li:hover h3 a {
      color: #000;
      -webkit-text-fill-color: #000;
      /* Will override color (regardless of order) */ }
      .agencies-list-title li.active h3 a:after, .agencies-list-title li:hover h3 a:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -3px;
        left: 0;
        transition: transform 0.3s;
        transform-origin: bottom left;
        transform: scaleX(1);
        background-color: #000; }
    .agencies-list-title li h3 {
      padding-right: 30px; }
      .agencies-list-title li h3 a {
        font-size: 2.25rem;
        font-weight: 700;
        color: transparent;
        -webkit-text-fill-color: transparent;
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        white-space: nowrap; }
  .agencies-list-image {
    overflow: hidden;
    position: relative;
    width: 317px;
    display: flex;
    flex-shrink: 0; }
    .agencies-list-image-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: -100%;
      z-index: 2; }
      .agencies-list-image-content.active {
        left: 0;
        position: static;
        z-index: 1; }
      .agencies-list-image-content-text {
        position: absolute;
        display: inline-block;
        padding: 30px;
        background: rgba(255, 255, 255, 0.9);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: left;
        font-size: 1rem;
        letter-spacing: 0; }
        @media (max-width: 1599.98px) {
          .agencies-list-image-content-text {
            padding: 10px; } }
  @media (max-width: 1599.98px) {
    .agencies .bloc-title {
      text-align: center; }
    .agencies .bloc-description {
      max-width: 500px;
      margin: 0 auto 40px;
      text-align: center; }
    .agencies .bloc-buttons {
      margin-bottom: 50px;
      text-align: center; } }
  @media (max-width: 767.98px) {
    .agencies {
      padding: 50px 10px; }
      .agencies .agencies-list {
        justify-content: center !important; }
        .agencies .agencies-list-image {
          display: none; }
        .agencies .agencies-list-title li h3 {
          padding: 0;
          text-align: center; } }

/*
|--------------------
|     Actualités
|--------------------
*/
.news {
  margin-bottom: 7rem; }
  .news .bloc-title {
    margin-bottom: 7rem; }
  .news-picture {
    margin-bottom: 1.875rem;
    overflow: hidden;
    border: 1.25rem solid #fff; }
  .news-title h3 {
    font-weight: 700;
    font-size: 1.125rem;
    margin: 0 0 1.25rem;
    padding: 0 1.25rem; }
  .news-description {
    padding: 0 1.25rem; }
  .news .container {
    margin-bottom: 40px; }
  @media (max-width: 767.98px) {
    .news .bloc-title {
      margin-bottom: 2rem; } }

/*
|--------------------
|     Instagram
|--------------------
*/
.instagram {
  margin-bottom: 7rem;
  background: #F7F5F0;
  padding: 0 7rem 3.375rem; }
  .instagram .wpmi-list li.wpmi-item {
    justify-content: center; }
    .instagram .wpmi-list li.wpmi-item img {
      border: 2rem solid #fff;
      max-width: 100%;
      height: auto; }
      @media (max-width: 1599.98px) {
        .instagram .wpmi-list li.wpmi-item img {
          border: 0.5rem solid #fff; } }
      @media (max-width: 991.98px) {
        .instagram .wpmi-list li.wpmi-item img {
          border: none; } }
  @media (max-width: 1599.98px) {
    .instagram {
      padding: 3.375rem; } }
  @media (max-width: 991.98px) {
    .instagram {
      padding: 1.25rem; } }

/*
|--------------------
|     Rejoignez-nous
|--------------------
*/
.join-us {
  margin-bottom: 7rem;
  position: relative; }
  .join-us:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 230px;
    transform: translate(0, -50px);
    background: #fff;
    z-index: -1; }
  .join-us-image {
    position: relative; }
    .join-us-image:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50px);
      width: 230px;
      height: 230px;
      background: url("../img/global/bk-moriss-gray.svg");
      background-size: contain;
      z-index: -1; }
  .join-us-description {
    max-width: 220px;
    margin: 0 0 30px; }
  @media (max-width: 767.98px) {
    .join-us-description {
      max-width: none;
      text-align: center; }
    .join-us .bloc-buttons {
      text-align: center; } }

/*
|--------------------
|     Groupe Moriss Immobilier
|--------------------
*/
.group-moriss {
  margin-bottom: 150px; }
  .group-moriss .bloc-title {
    margin-bottom: 100px; }
  .group-moriss-description {
    max-width: 300px;
    margin: 0; }

@media (max-width: 1199.98px) and (max-width: 1199.98px) {
  .group-moriss-description {
    max-width: none; } }
  @media (max-width: 1199.98px) {
    .group-moriss-image {
      margin-bottom: 50px; } }

/*
|--------------------
|     Les agences
|--------------------
*/
.group-agencies {
  margin-bottom: 100px; }
  .group-agencies .bloc-title {
    margin-bottom: 150px; }
  .group-agencies-bloc {
    position: relative;
    margin-bottom: 80px; }
    .group-agencies-bloc-title {
      position: absolute;
      left: 0;
      bottom: -30px;
      font-size: 2.375rem;
      font-weight: 700;
      color: #000;
      -webkit-text-fill-color: transparent;
      /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
      transition: all 0.5s; }
      .group-agencies-bloc-title.active {
        color: #000;
        -webkit-text-fill-color: #000; }
    .group-agencies-bloc-informations {
      font-size: 1rem;
      font-weight: 400;
      color: #333;
      margin-bottom: 20px; }
    .group-agencies-bloc-description {
      font-size: 1rem;
      color: #7E7E7E;
      margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .group-agencies .bloc-title {
      margin-bottom: 30px; }
    .group-agencies-bloc {
      margin-bottom: 40px; }
      .group-agencies-bloc-title {
        left: 50%;
        transform: translate(-50%, 0);
        white-space: nowrap; }
      .group-agencies-bloc-title.no-transition {
        left: 0;
        transform: none; } }

.single-biens .group-agencies-bloc-title {
  position: static; }

/*
|--------------------
|     Highlight
|--------------------
*/
.highlight-block {
  border: 1px solid #000;
  font-size: 3.125rem;
  font-weight: 700;
  margin: 0 auto 150px;
  overflow: hidden;
  padding: 0 100px; }
  .highlight-block-content {
    overflow: hidden; }
    .highlight-block-content ul li {
      transition: all 0.5s;
      display: inline-block;
      margin: 0 50px;
      padding: 100px 0;
      color: #333;
      -webkit-text-fill-color: transparent;
      /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: black; }
      .highlight-block-content ul li:hover {
        color: #333;
        -webkit-text-fill-color: #333; }
  @media (max-width: 1199.98px) {
    .highlight-block {
      padding: 0 10px; } }
  @media (max-width: 991.98px) {
    .highlight-block {
      font-size: 2rem; }
      .highlight-block-content ul li {
        margin: 0 50px;
        padding: 10px 0; } }

/*
|--------------------
|     Equipe
|--------------------
*/
.team {
  padding: 10px 100px 100px;
  margin-bottom: 120px;
  position: relative; }
  .team .bloc-title {
    margin-bottom: 70px; }
  .team-buttons {
    position: absolute;
    bottom: -16px;
    left: 0;
    text-align: center;
    width: 100%; }
  .team:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 490px;
    background: #F7F5F0;
    z-index: -1; }
  .team:after {
    content: '';
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 458px;
    height: 430px;
    z-index: -1;
    background: url("../img/global/bk-moriss-gray.svg");
    background-size: contain; }
  @media (max-width: 767.98px) {
    .team {
      padding: 50px 10px;
      background: #F7F5F0; }
      .team:before, .team:after {
        display: none; }
      .team-buttons {
        margin-top: 10px;
        position: static; }
        .team-buttons .ml-5 {
          margin: 10px 0 0 0 !important; } }

/*
|--------------------
|     Bloc titre
|--------------------
*/
.sale-title {
  margin-bottom: 130px; }

/*
|--------------------
|     Estimation en ligne
|--------------------
*/
.estimate-online {
  margin-bottom: 130px; }
  .estimate-online .container {
    padding: 50px;
    border: 1px solid #000; }
  @media (max-width: 1199.98px) {
    .estimate-online {
      padding: 10px;
      background: #fff; }
      .estimate-online .container {
        box-shadow: none !important; } }
  @media (max-width: 1199.98px) {
    .estimate-online .container {
      padding: 50px 10px; } }

/*
|--------------------
|     Estimation Moriss Immobilier
|--------------------
*/
.estimate-moriss-immobilier {
  margin-bottom: 7rem;
  padding: 60px;
  position: relative; }
  .estimate-moriss-immobilier-description {
    max-width: 400px;
    margin: 0 0 30px; }
    @media (max-width: 991.98px) {
      .estimate-moriss-immobilier-description {
        max-width: none; } }
  .estimate-moriss-immobilier:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: #fff; }
  .estimate-moriss-immobilier-wrap-image {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative; }
    .estimate-moriss-immobilier-wrap-image img {
      max-width: none; }
    .estimate-moriss-immobilier-wrap-image:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(70%, -50%);
      width: 458px;
      height: 430px;
      z-index: -1;
      background: url("../img/global/bk-moriss-gray.svg");
      background-size: contain; }
  .estimate-moriss-immobilier-right {
    padding: 0 0 0 200px; }
  @media (max-width: 991.98px) {
    .estimate-moriss-immobilier {
      background: #F7F5F0; }
      .estimate-moriss-immobilier:before {
        display: none; } }
  @media (max-width: 767.98px) {
    .estimate-moriss-immobilier {
      padding: 50px 10px; } }

.buy .description {
  max-width: 800px;
  margin: 0 auto; }

/*
|--------------------
|     Filtres
|--------------------
*/
.filters {
  margin-bottom: 100px;
  position: relative;
  z-index: 1; }
  .filters-group {
    margin: 30px 0 10px; }
    .filters-group-label {
      margin-bottom: 0px; }
    .filters-group .selectize-input {
      background: transparent;
      box-shadow: none;
      -webkit-box-shadow: none;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #000; }
    .filters-group .selectize-dropdown .active {
      background: #f6f6f6; }
    .filters-group .selectize-dropdown,
    .filters-group .selectize-input,
    .filters-group .selectize-input input {
      font-size: 0.875rem; }
    .filters-group .nice-select {
      float: none; }
  .filters .row .col-12 em {
    color: #aaa;
    font-style: normal;
    font-size: 0.75rem; }
  .filters .row input {
    width: calc(100% - 30px);
    background: transparent; }

/*
|--------------------
|     Single
|--------------------
*/
.product-bloc1 {
  margin-bottom: 150px; }
  .product-bloc1 ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%; }
  .product-bloc1 iframe {
    border: none; }
  .product-bloc1 .bloc-title .subtitle {
    margin-bottom: 20px; }
  .product-bloc1 .bloc-title .price {
    font-size: 2rem; }
  @media (max-width: 767.98px) {
    .product-bloc1 .price {
      text-align: center; }
    .product-bloc1 .simpleParallax {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .product-bloc1 {
      margin-bottom: 50px; } }
  .product-bloc1 .products-images-line-link-wrap-view {
    font-size: 1.5rem; }

.product-bloc2 {
  margin-bottom: 120px; }
  .product-bloc2-description {
    padding: 50px;
    border: 1px solid #000; }
    .product-bloc2-description .description {
      margin-bottom: 30px; }
    .product-bloc2-description .description-2 {
      font-weight: 700; }
    .product-bloc2-description-informations {
      padding-top: 0; }
      .product-bloc2-description-informations-title {
        margin-top: 40px;
        margin-bottom: 40px;
        font-family: 'adobegaramond';
        font-size: 1.25rem; }
        .product-bloc2-description-informations-title:first-child {
          margin-top: 0; }
      .product-bloc2-description-informations-col2, .product-bloc2-description-informations-col3 {
        padding-top: 67px; }
        .product-bloc2-description-informations-col2 .disable-animation::after, .product-bloc2-description-informations-col3 .disable-animation::after {
          display: none; }
        .product-bloc2-description-informations-col2 sup, .product-bloc2-description-informations-col3 sup {
          padding: 1px 5px;
          background: #3a3a3a;
          color: #fff;
          border-radius: 50%;
          margin-left: 5px;
          font-size: 10px; }
          @media (max-width: 1199.98px) {
            .product-bloc2-description-informations-col2 sup, .product-bloc2-description-informations-col3 sup {
              padding: 1px 6px; } }
    .product-bloc2-description .contact-form .gform_wrapper ul.gform_fields li.gfield {
      padding: 0;
      margin-top: 0; }
    .product-bloc2-description .contact-form .gform_wrapper .top_label .gfield_label, .product-bloc2-description .gform_wrapper legend.gfield_label {
      left: 0; }
  .product-bloc2 .group-agencies-bloc {
    margin-bottom: 50px; }
  @media (max-width: 1199.98px) {
    .product-bloc2-description .description {
      margin-bottom: 30px; }
    .product-bloc2-description-informations-col2 .d-flex {
      display: block !important; }
    .product-bloc2-description > div {
      display: block !important; } }

/*
|--------------------
|      SINGLE
|--------------------
*/
.post-date {
  font-size: 12px;
  color: rgba(153, 153, 153, 0.6);
  text-transform: uppercase;
  font-weight: 400; }

.post-excerpt {
  font-size: 22px;
  color: rgba(58, 58, 58, 0.6);
  font-weight: 400; }

/*
|--------------------
|     Agences
|--------------------
*/
.agencies-presentation {
  margin-bottom: 150px; }
  .agencies-presentation .description {
    margin-bottom: 0; }
  .agencies-presentation .map {
    max-width: 600px; }

/*
|--------------------
|     Single
|--------------------
*/
/*
|--------------------
|     Présentation
|--------------------
*/
.agency-presentation {
  margin-bottom: 150px; }
  .agency-presentation-description {
    margin-bottom: 20px; }
  .agency-presentation-address {
    font-size: 0.75rem;
    font-weight: 700;
    color: #333; }
  .agency-presentation ul li:first-child {
    margin-bottom: 17px; }
  @media (max-width: 1199.98px) {
    .agency-presentation-address {
      margin-bottom: 20px; } }
  @media (max-width: 991.98px) {
    .agency-presentation ul li:first-child {
      margin-bottom: 0; }
    .agency-presentation .simpleParallax {
      margin-bottom: 20px; } }

/*
|--------------------
|     Equipe
|--------------------
*/
.agency-team {
  margin-bottom: 120px; }
  .agency-team .bloc-title {
    margin-bottom: 120px; }
  .agency-team-picture {
    margin: 0 0 20px; }
    .agency-team-picture img {
      max-width: 100%; }

/*
|--------------------
|     Biens
|--------------------
*/
.agency-products {
  margin-bottom: 120px; }
  .agency-products .bloc-title {
    margin-bottom: 120px; }

/*
|--------------------
|     Avis
|--------------------
*/
.agency-avis {
  margin-bottom: 120px; }
  .agency-avis .bloc-title {
    margin-bottom: 120px; }

/*
|--------------------
|     Team
|--------------------
*/
.team-images {
  margin-bottom: 120px; }
  .team-images-filters {
    margin-bottom: 100px; }
    .team-images-filters ul {
      display: flex;
      justify-content: center; }
      .team-images-filters ul li a {
        color: #7E7E7E; }
      .team-images-filters ul li.separator {
        padding: 0 10px; }
  @media (max-width: 991.98px) {
    .team-images ul {
      display: block;
      text-align: center; }
      .team-images ul li {
        border: none;
        margin-bottom: 10px; } }

/*
|--------------------
|     Avis
|--------------------
*/
.avis-bloc {
  margin-bottom: 120px; }
  .avis-bloc-filters {
    margin-bottom: 100px; }
    .avis-bloc-filters ul {
      display: flex;
      justify-content: center; }
      .avis-bloc-filters ul li a {
        color: #7E7E7E; }
      .avis-bloc-filters ul li.separator {
        padding: 0 10px; }
  @media (max-width: 991.98px) {
    .avis-bloc-filters {
      margin-bottom: 30px !important; }
    .avis-bloc ul {
      display: block;
      text-align: center; }
      .avis-bloc ul li {
        border: none;
        margin-bottom: 10px; } }

/*
|--------------------
|     Carrier - Header
|--------------------
*/
#page-carrier.top-bk-with-breadcrumb:before {
  height: 600px; }

.carrier-header {
  margin-bottom: 160px;
  position: relative; }
  .carrier-header .bloc-title {
    margin-bottom: 75px; }
  .carrier-header:before {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 245px;
    height: 230px;
    background: url("../img/global/bk-moriss-gray.svg");
    background-size: contain;
    z-index: -1; }

/*
|--------------------
|     Carrier - Présentation
|--------------------
*/
.carrier-presentation {
  padding: 100px 0;
  position: relative;
  margin-bottom: 125px; }
  .carrier-presentation:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60%;
    height: 100%;
    background: #F7F5F0;
    z-index: -1; }
  .carrier-presentation .description {
    max-width: 400px;
    margin-bottom: 30px; }
  .carrier-presentation-strength-title {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'adobegaramond';
    font-size: 1.25rem; }
    .carrier-presentation-strength-title:first-child {
      margin-top: 0; }
  @media (max-width: 1199.98px) {
    .carrier-presentation {
      background: #F7F5F0;
      padding: 50px 10px;
      text-align: center; }
      .carrier-presentation:before {
        display: none; }
      .carrier-presentation .bloc-title {
        margin-top: 30px; }
      .carrier-presentation .description {
        margin: 0 auto 30px; }
      .carrier-presentation .buttons {
        margin-bottom: 30px; }
      .carrier-presentation-strength-title {
        margin-top: 20px;
        margin-bottom: 10px; } }

/*
|--------------------
|     Carrier - Rejoignez-nous
|--------------------
*/
.carrier-join {
  background: #F7F5F0;
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    .carrier-join {
      padding: 50px 10px; } }

/*
|--------------------
|     News
|--------------------
*/
#page-news.top-bk-with-breadcrumb:before, #page-news-single.top-bk-with-breadcrumb:before {
  height: 600px; }

/*
|--------------------
|     News - Single
|--------------------
*/
.news-single-picture {
  margin-bottom: 1.25rem; }

.news-single-prevnext {
  margin-bottom: 125px; }

@media (max-width: 991.98px) {
  .news-single .description {
    margin-bottom: 50px; }
  .news-single-prevnext {
    margin-bottom: 0; } }

.news .posts, .news .wrap-posts {
  margin-top: -30px; }

.news .post {
  margin-top: 30px; }

/*
|--------------------
|       Contact
|--------------------
*/
#page-contact {
  /*
    |
    | Section contact
    |------------------
    */ }
  #page-contact .section-map #map {
    position: relative;
    width: 100%;
    height: 50vh;
    background-color: #000000; }

/*
|--------------------
|       Confirmation
|--------------------
*/
#page-confirmation .sale-estimate-result {
  margin-bottom: 7rem; }
  #page-confirmation .sale-estimate-result-text {
    font-size: 1.875rem; }
    #page-confirmation .sale-estimate-result-text .bloc-buttons {
      margin-top: 3.375rem; }
    #page-confirmation .sale-estimate-result-text-number {
      font-weight: 700; }

.contact {
  margin-bottom: 150px; }

/*
|--------------------
|       Estimez mon bien
|--------------------
*/
#page-estimate {
  /*
    |
    | Section contact
    |------------------
    */ }
  #page-estimate .estimate-form {
    margin-bottom: 7rem; }
    #page-estimate .estimate-form .bloc-title {
      margin-bottom: 80px; }

/*
|--------------------
|     Vidéos
|--------------------
*/
.videos {
  margin-bottom: 7rem; }
  .videos .video-picture {
    margin-bottom: 1.875rem;
    overflow: hidden;
    border: 1rem solid #fff; }
  .videos .video-category {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: normal;
    border-bottom: 1px solid #000;
    padding: 0 1.25rem 0.5rem 0;
    margin: 0 0 1.25rem; }
  .videos .video-title {
    font-weight: 700;
    font-size: 1.125rem;
    margin: 0 0 1.25rem;
    padding: 0; }
  .videos .video-description {
    padding: 0;
    margin: 0 0 1.25rem; }
  .videos .video-links {
    padding: 0;
    margin: 0 0 1.25rem; }
  .videos .container {
    margin-bottom: 40px; }

#page-video .bloc-title {
  margin-bottom: 5.5rem; }

#page-video .video-talk-us {
  margin-bottom: 5.5rem; }
  #page-video .video-talk-us .title {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1.25rem; }
  #page-video .video-talk-us ul li {
    padding: 0 0.5rem; }

#page-video .video-bloc-content {
  margin-top: 1.25rem; }

#page-video .avis-bloc-filters {
  margin-bottom: 1.25rem; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn, .contact-form input[type="submit"] {
  border: 1px solid #000;
  padding: 0;
  background: transparent;
  box-shadow: inset 0 0 0 1px #000;
  color: #000;
  transition: color 0.25s 0.08333s;
  position: relative;
  position: relative;
  overflow: hidden;
  z-index: 2;
  color: #3A3A3A;
  border-radius: 0;
  box-shadow: none;
  font-size: 1.125rem; }
  .btn::before, .contact-form input[type="submit"]::before, .btn::after, .contact-form input[type="submit"]::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    background: transparent;
    bottom: 0;
    left: 0; }
  .btn::before, .contact-form input[type="submit"]::before {
    border-bottom-width: 1px;
    border-right-width: 1px; }
  .btn::after, .contact-form input[type="submit"]::after {
    border-top-width: 1px;
    border-left-width: 1px; }
  .btn:hover, .contact-form input:hover[type="submit"] {
    color: #000; }
    .btn:hover::before, .contact-form input[type="submit"]:hover::before, .btn:hover::after, .contact-form input[type="submit"]:hover::after {
      border-color: #000;
      transition: border-color 0s, width 0.25s, height 0.25s;
      width: 100%;
      height: 100%;
      background: transparent; }
    .btn:hover::before, .contact-form input[type="submit"]:hover::before {
      transition-delay: 0s, 0s, 0.25s; }
    .btn:hover::after, .contact-form input[type="submit"]:hover::after {
      transition-delay: 0s, 0.25s, 0s; }
  .btn span, .contact-form input[type="submit"] span {
    display: block;
    padding: 5px 15px; }
    .btn span:before, .contact-form input[type="submit"] span:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-100%, 0);
      background: url("../img/icon/line.svg") center right transparent;
      width: 300px;
      height: 100%;
      transition: all 0.5s;
      z-index: -1; }
    .btn span:hover:before, .contact-form input[type="submit"] span:hover:before {
      transform: translate(0, 0); }

.btn.transition-white span:before, .contact-form input.transition-white[type="submit"] span:before {
  background: url("../img/icon/line-white.svg") center right; }

.btn.transition-white.btn-beige, .contact-form input.transition-white.btn-beige[type="submit"] {
  background: #F7F5F0; }
  .btn.transition-white.btn-beige span:before, .contact-form input.transition-white.btn-beige[type="submit"] span:before {
    background: url("../img/icon/line-white-big.svg") center right transparent;
    width: 400px; }
  @media (max-width: 767.98px) {
    .btn.transition-white.btn-beige, .contact-form input.transition-white.btn-beige[type="submit"] {
      text-align: center;
      width: auto; }
      .btn.transition-white.btn-beige span, .contact-form input.transition-white.btn-beige[type="submit"] span {
        padding: 10px 30px; } }

.btn-big span {
  padding: 10px 50px;
  font-size: 1.5rem; }
  @media (max-width: 1599.98px) {
    .btn-big span {
      font-size: 1.125rem; } }

.btn-inverse {
  background: #000;
  color: #fff; }

.logo-line-path {
  background: #F7F5F0; }

/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 0;
  border-bottom: solid 1px #000;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: calc(2.25rem + 2px);
  line-height: 2.6;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 100%; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0);
    max-height: 400px;
    overflow: scroll; }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: auto;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-calc(2.25rem + 2px)/2);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 2.2;
    list-style: none;
    min-height: 2rem;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select {
  border-radius: 0; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

input {
  border: none;
  border-bottom: 1px solid #000;
  line-height: 2.6; }

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap .gform_fields {
  display: flex !important;
  flex-wrap: wrap !important; }
  .gravity-form-bootstrap .gform_fields input, .gravity-form-bootstrap .gform_fields textarea {
    width: 100%;
    background-color: transparent; }

/*
|
| Materialize form 
|-------------------
*/
.materialize-form .materialize-form-group {
  position: relative;
  margin-bottom: 25px; }
  .materialize-form .materialize-form-group label {
    position: absolute;
    top: 0;
    left: 15px;
    font-family: 'adobegaramond';
    font-size: 1.25rem !important; }
  .materialize-form .materialize-form-group input {
    border: none;
    border-bottom: 1px #3A3A3A solid;
    color: #3A3A3A; }
  .materialize-form .materialize-form-group input[type=file] {
    border: none;
    height: auto; }
  .materialize-form .materialize-form-group.gfield_error label {
    position: static; }

/*
|
| Contact form 
|---------------
*/
.contact-form label {
  color: #3A3A3A;
  font-family: 'adobegaramond';
  font-size: 1.125rem !important; }

.contact-form textarea {
  border: 1px #3A3A3A solid;
  height: 120px;
  padding: 5px 10px;
  color: #3A3A3A; }
  .contact-form textarea.placeholder_disabled {
    color: #3A3A3A; }

.contact-form .ginput_container_fileupload {
  padding: 0 0 0 50px; }

.contact-form .gform_body {
  margin-bottom: 20px; }

.contact-form .gform_footer {
  text-align: center; }

.contact-form input {
  width: 100% !important; }

.contact-form .gform_confirmation_message {
  color: #3A3A3A; }

.contact-form .validation_message {
  margin-top: 5px;
  color: #BB0B0B;
  font-size: 14px; }

.contact-form #field_1_6 label, .contact-form #field_2_8 label, .contact-form #field_3_44 label, .contact-form #field_13_44 label {
  font-family: 'adobegaramond';
  font-size: 0.875rem; }

.contact-form #field_1_6 > label, .contact-form #field_2_8 > label, .contact-form #field_3_44 > label, .contact-form #field_4_1 > label, .contact-form #field_5_1 > label, .contact-form #field_13_44 > label {
  display: none; }

.contact-form #choice_1_6_1, .contact-form #choice_2_8_1, .contact-form #choice_3_44_1, .contact-form #choice_13_44_1 {
  margin-right: 20px; }

.contact-form #label_1_6_1, .contact-form #label_2_8_1, .contact-form #label_3_44_1, .contact-form #label_13_44_1 {
  color: #7E7E7E;
  font-size: 0.875rem !important; }

.contact-form #field_1_2, .contact-form #field_1_5 {
  max-width: none !important;
  padding-right: 16px; }

.contact-form .gform_wrapper ul.gfield_checkbox li input[type=checkbox], .contact-form .gform_wrapper ul.gfield_radio li input[type=radio] {
  width: auto !important; }

.contact-form #input_2_4 {
  font-size: 0.5rem; }

.validation_error {
  margin-bottom: 30px;
  color: #BB0B0B !important; }

#gform_ajax_spinner_1 {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

/* Estimation en ligne */
#field_3_1 > label,
#field_13_1 > label {
  display: none;
  margin-top: 0;
  margin-bottom: 10px; }

#input_3_1, #input_3_23, #input_4_1, #input_5_1, #input_13_1, #input_13_23 {
  display: flex;
  justify-content: left; }
  #input_3_1 li, #input_3_23 li, #input_4_1 li, #input_5_1 li, #input_13_1 li, #input_13_23 li {
    text-align: center;
    margin: 0 20px; }
    #input_3_1 li label, #input_3_23 li label, #input_4_1 li label, #input_5_1 li label, #input_13_1 li label, #input_13_23 li label {
      position: static;
      margin-left: 10px; }
    @media (max-width: 767.98px) {
      #input_3_1 li, #input_3_23 li, #input_4_1 li, #input_5_1 li, #input_13_1 li, #input_13_23 li {
        margin: 0; } }

#input_4_1, #input_5_1 {
  justify-content: center; }

#choice_3_44_1,
#choice_13_44_1 {
  margin-top: 0; }

.gform_page_footer {
  text-align: center;
  border: none !important; }

.gf_progressbar {
  display: none;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
  width: auto !important;
  margin: 30px 10px 10px; }
  .gf_progressbar:after {
    display: none !important; }
  .gf_progressbar_percentage {
    text-shadow: none !important;
    background: #7c7c7c !important;
    border-radius: 0 !important;
    height: 2px !important;
    color: #000 !important; }

.gfieldset {
  padding: 0 10px 50px;
  margin-top: 50px;
  position: relative; }
  .gfieldset legend {
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase; }
    .gfieldset legend span {
      position: relative;
      display: inline-block;
      font-weight: 700;
      font-size: 1rem; }
  .gfieldset:last-child:after {
    display: none; }
  @media (max-width: 767.98px) {
    .gfieldset {
      padding: 0 0 50px; } }

.filter-form label {
  position: static !important; }

#field_3_47 {
  margin-top: 50px; }

#gform_6 .gform_footer,
#gform_14 .gform_footer {
  text-align: center; }

#gform_confirmation_wrapper_6 {
  margin-top: 7rem; }

.gform_validation_error.gform_wrapper .top_label .gfield_label, .gform_wrapper legend.gfield_label {
  position: static; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }
