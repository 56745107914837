/*
|--------------------
|       Estimez mon bien
|--------------------
*/

#page-estimate{
    /*
    |
    | Section contact
    |------------------
    */
    .estimate-form{
        margin-bottom: $size-100;
        .bloc-title {
            margin-bottom: 80px;
        }
    }
}
