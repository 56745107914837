/*
|--------------------
|      FOOTER
|--------------------
*/
#footer{
    padding-top: 120px;
    .footer-content {
        background: #F7F5F0;
        padding: 0 30px 30px;

        .estimate-image {
            text-align: center;
            position: relative;
            top: -90px;
            &-link {
                display: inline-block;

                &-title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    color: #fff;
                    text-decoration: underline;
                    font-size: $font-size-58;
                    font-weight: 700;
                    @include media-breakpoint-down(md) {
                        font-size: $font-size-30;
                    }
                }
            }
        }

        .presentation-description {
            margin-top: 20px;
        }

        .footer-menu {
            li {
                width: 230px;
                margin-bottom: 10px;
                a {
                    font-size: $font-size-24;
                    line-height: 2;
                    font-weight: 700;
                }
            }
        }

        .copyright {
            font-size: $font-size-12;
            padding-top: 11px;
        }
        @include media-breakpoint-down(lg) {
            .footer-menu {
                li {
                    width: 180px;
                    a {
                        font-size: $font-size-18;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            text-align: center;
            .presentation-description {
                display: none;
            }
            .footer-menu {
                margin-top: 30px;
                li {
                    width: 33.33%;
                }
            }
            .social-languages {
                justify-content: center !important;
            }

            justify-content: center !important;
        }
        @include media-breakpoint-down(sm) {
            .footer-menu {
                li {
                    width: 50%;
                }
            }
            .estimate-image {
                position: static;
                margin-bottom: 30px;
                padding-top: 30px;
                &-link {
                    &-title {
                        font-size: $font-size-18;
                    }
                }
            }
        }
    }

    .lang-container{
        position: relative;
        display: inline-flex;
        top: 1px;
        @extend .link-menu;
        cursor: pointer;

        ul{
            position: absolute;
            z-index: 1;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateX(10px);
            transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
            @include media-breakpoint-down(md) {
                position: static;
                opacity: 1;
                visibility: visible;
                transform: translateX(0)!important;
            }
        }

        .lang-item, .item-lang {
            padding: 10px;
        }

        &:hover{
            ul{
                opacity: 1!important;
                visibility: visible!important;
                transform: translateX(0)!important;
            }
        }
    }

    .social a {
        opacity: 0.6;
    }

    .newsletter {
        &-field {
            padding: 0;
            .gfield_description {
                font-size: $size-18;
                font-weight: 700;
            }
            .validation_message {
                font-size: $size-12;
                font-weight: 700;
            }
            input {
                margin: 0;
                padding: 10px;
                border: 1px solid #aaa;
                line-height: normal;
            }
        }
        .gform_body {
            margin-bottom: 10px;
        }
        .gform_footer {
            margin-top: 0;
            padding: 0;
            text-align: left;
            .btn span {
                font-size: $size-12;
            }
        }
        .gform_confirmation_message {
            color: green;
            margin-top: 40px;
            font-weight: 700;
        }
        @include media-breakpoint-down(md) {
            text-align: center;
            &-field {
                .gfield_description {
                    text-align: center;
                }
                input {
                    text-align: center;
                }
            }
            .gform_footer {
                text-align: center;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}