/*
|--------------------
|     Groupe Moriss Immobilier
|--------------------
*/

.group-moriss {
    margin-bottom: 150px;
    .bloc-title {
        margin-bottom: 100px;
    }
    &-description {
        max-width: 300px;
        margin: 0;
    }
    @include media-breakpoint-down(lg) {
        &-description {
            @include media-breakpoint-down(lg) {
                max-width: none;
            }
        }
        &-image {
            margin-bottom: 50px;
        }
    }
}

/*
|--------------------
|     Les agences
|--------------------
*/
.group-agencies {
    margin-bottom: 100px;
    .bloc-title {
        margin-bottom: 150px;
    }
    &-bloc {
        position: relative;
        margin-bottom: 80px;
        &-image {

        }
        &-title {
            position: absolute;
            left: 0;
            bottom: -30px;
            font-size: $font-size-36;
            font-weight: 700;
            color: #000;
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
            transition: all 0.5s;
            &.active {
                color: #000;
                -webkit-text-fill-color: #000;
            }
        }
        &-informations {
            font-size: $font-size-16;
            font-weight: 400;
            color: #333;
            margin-bottom: 20px;
        }
        &-description {
            font-size: $font-size-16;
            color: #7E7E7E;
            margin-bottom: 30px;
        }
    }
    @include media-breakpoint-down(sm) {
        .bloc-title {
            margin-bottom: 30px;
        }
        &-bloc {
            margin-bottom: 40px;
            &-title {
                left: 50%;
                transform: translate(-50%, 0);
                white-space: nowrap;
            }
            &-title.no-transition {
                left: 0;
                transform: none;
            }
        }

    }
}

.single-biens {
    .group-agencies-bloc-title {
        position: static;
    }
}

/*
|--------------------
|     Highlight
|--------------------
*/
.highlight-block {
    border: 1px solid #000;
    font-size: $font-size-46;
    font-weight: 700;
    margin: 0 auto 150px;
    overflow: hidden;
    padding: 0 100px;
    &-content {
        overflow: hidden;
        ul {
            li {
                transition: all 0.5s;
                display: inline-block;
                margin: 0 50px;
                padding: 100px 0;
                color: #333;
                -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: black;
                &:hover {
                    color: #333;
                    -webkit-text-fill-color: #333;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        padding: 0 10px;
    }
    @include media-breakpoint-down(md) {
        font-size: $font-size-30;
        &-content {
            ul {
                li {
                    margin: 0 50px;
                    padding: 10px 0;
                }
            }
        }
    }
}

/*
|--------------------
|     Equipe
|--------------------
*/
.team {
    padding: 10px 100px 100px;
    margin-bottom: 120px;
    position: relative;
    .bloc-title {
        margin-bottom: 70px;
    }
    &-buttons {
        position: absolute;
        bottom: -16px;
        left: 0;
        text-align: center;
        width: 100%;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 490px;
        background: #F7F5F0;
        z-index: -1;
    }
    &:after {
        content: '';
        position: absolute;
        top: 130px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 458px;
        height: 430px;
        z-index: -1;
        background: url('../img/global/bk-moriss-gray.svg');
        background-size: contain;
    }
    @include media-breakpoint-down(sm) {
        padding: 50px 10px;
        background: #F7F5F0;
        &:before, &:after { display: none;}
        &-buttons {
            margin-top: 10px;
            position: static;
            .ml-5 {
                margin: 10px 0 0 0 !important;
            }
        }
    }
}