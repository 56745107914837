/*
|--------------------
|     News
|--------------------
*/
#page-news.top-bk-with-breadcrumb:before, #page-news-single.top-bk-with-breadcrumb:before {
    height: 600px;
}

/*
|--------------------
|     News - Single
|--------------------
*/
.news-single {
    &-picture {
        margin-bottom: $size-20;
    }
    &-prevnext {
        margin-bottom: 125px;
    }
    @include media-breakpoint-down(md) {
        .description {
            margin-bottom: 50px;
        }
        &-prevnext {
            margin-bottom: 0;
        }
    }
}

.news {
    .posts, .wrap-posts {
        margin-top: -30px;
    }
    .post {
        margin-top: 30px;
    }
}