/*
|--------------------
|     Vidéos
|--------------------
*/
.videos {
    margin-bottom: $size-100;
    .video {
        &-picture {
            margin-bottom: $size-28;
            overflow:hidden;
            border: 1rem solid #fff;
        }
        &-category {
            display: inline-block;
            font-size: $size-12;
            font-weight: normal;
            border-bottom: 1px solid #000;
            padding: 0 $size-20 $size-10 0;
            margin: 0 0 $size-20;
        }
        &-title {
            font-weight: 700;
            font-size: $font-size-18;
            margin: 0 0 $size-20;
            padding: 0;
        }
        &-description {
            padding: 0;
            margin: 0 0 $size-20;
        }
        &-links {
            padding: 0;
            margin: 0 0 $size-20;
        }
    }

    .container {
        margin-bottom: 40px;
    }
}

#page-video {
    .bloc-title {
        margin-bottom: $size-80;
    }
    .video {
        &-talk-us {
            .title {
                text-align: center;
                font-size: $font-size-18;
                font-weight: 700;
                margin-bottom: $size-20;
            }
            margin-bottom: $size-80;
            ul {
                li {
                    padding: 0 $size-10;
                }
            }
        }
        &-bloc-content {
            margin-top: $size-20;
        }
    }
    .avis-bloc-filters {
        margin-bottom: $size-20;
    }
}