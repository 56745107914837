/*
|--------------------
|     PAGE HOME
|--------------------
*/

/*
|--------------------
|     SPLASHSCREEN
|--------------------
*/
.splashscreen {
  position: relative;
  margin-bottom: $size-100;

  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .splashscreen-left {
      padding: 30px;
      z-index: 2;

      h1 {
        font-size: $font-size-56;
        font-weight: 700;
      }

      h2 {
        font-size: $font-size-28;
        font-family: adobegaramond;
      }

      &-style {
        display: none;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 500px;
        height: 400px;
        background: #F7F5F0;
        z-index: -1;
      }

      @include media-breakpoint-down(xl) {
        h1 {
          font-size: $font-size-40;
        }
        h2 {
          font-size: $font-size-18;
        }
        &-style {
          height: 200px;
        }
      }
      @include media-breakpoint-down(sm) {
        h1 {
          font-size: $font-size-24;
        }
        h2 {
          font-size: $font-size-16;
        }
      }
    }

    .splashscreen-right {
      padding: 30px 90px 30px;
      position: relative;

      &-line {
        a {
          text-align: center;
        }

        &-first {
          margin-bottom: $size-20;
        }
      }

      &-style {
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translate(0, -50%);
        width: 458px;
        height: 430px;
        z-index: 1;
        background: url('../img/global/bk-moriss-gray.svg');
        background-size: contain;
      }

      @include media-breakpoint-down(xl) {
        padding: 10px 30px 10px;
        &-style {
          width: 213px;
          height: 200px;
        }
      }
      @include media-breakpoint-down(sm) {
        padding: 50px 10px 10px;
        &-style {
          display: none;
        }
      }
    }
  }

  .splashscreen-movie {
    iframe {
      width: 100%;
      height: 874px;
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: transparent;
    }
  }

  @include media-breakpoint-down(md) {
    .splashscreen-movie {
      padding-top: 120px;

      iframe {
        height: 490px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &-wrapper {
      position: static;
      display: block;
      text-align: center;

      .splashscreen-left {
        &-style {
          display: none;
        }
      }

      .splashscreen-right {
        &-style {
          right: auto;
          top: 50%;
          left: 50%;
        }
      }
    }
    .splashscreen-movie {
      iframe {
        height: 365px;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .splashscreen-movie {
      iframe {
        height: 280px;
      }
    }
  }
}

[data-aos^=fade][data-aos^=fade].aos-animate.splashscreen-left-style {
  transform: translate(0, -50%) !important;
}

[data-aos^=fade][data-aos^=fade].aos-animate.splashscreen-right-style {
  transform: translate(0, -50%) !important;
  @include media-breakpoint-down(sm) {
    transform: translate(-50%, -50%) !important;
  }
}

@include media-breakpoint-down(sm) {
  .splashscreen-wrapper [data-aos^=fade][data-aos^=fade] {
    opacity: 1 !important;
    transform: translateZ(0) !important;
    transition: none;

  }
}

/*
|--------------------
|     ESTIMER MON BIEN
|--------------------
*/
.estimate {
  margin-bottom: $size-100;

  &-content {
    padding: 60px;
    border: 1px solid #000;
  }

  .container {
    position: relative;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 0;
    &-content {
      padding: 30px 10px;
      border: 1px solid #000;
    }
  }
}

/*
|--------------------
|     MORISS IMMOBILIER
|--------------------
*/
.moriss-immobilier {
  margin-bottom: $size-150;
  padding: 60px;
  position: relative;

  &-description {
    max-width: 220px;
    margin: 0 0 30px;
    @include media-breakpoint-down(sm) {
      max-width: none;
      text-align: center;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    background: #F7F5F0;
    z-index: -1;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &-wrap-image {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    img {
      max-width: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-60%, -50%);
      width: 458px;
      height: 430px;
      background: url('../img/global/bk-moriss-gray.svg');
      background-size: contain;
      z-index: -1;
    }
  }

  &-right {
    padding: 0 0 0 160px;
    @include media-breakpoint-down(xl) {
      padding: 0 0 0 50px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 60px 10px;
    .buttons {
      text-align: center;
    }
  }
}

/*
|--------------------
|     LES BIENS
|--------------------
*/

.products {
  margin-bottom: $size-150;

  &-bloc {
    padding: 0 20px;
    height: 100%;
  }

  .bloc-title {
    margin-bottom: $size-100;
    position: relative;

    .btn {
      position: absolute;
      top: 6px;
      right: 0;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &-images {
    &-line {
      &-link {
        &-wrap {
          position: relative;

          &-view {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: $font-size-58;
            font-weight: 700;
            text-decoration: underline;
            @include media-breakpoint-down(xl) {
              font-size: $font-size-30;
            }
          }
        }

        &-wrap-3 {
          padding-left: 10px;
        }
      }
    }
  }

  &-content {
    margin-top: -50px;
    margin-bottom: 50px;
  }

  &-line {
    margin-top: 50px;

    &-image {
      margin-bottom: 10px;
      position: relative;

      &-status {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #f7f5f0;
        color: #000;
        padding: 10px 20px;
        font-size: $size-20;

        &.product-sous-offre, &.product-sous-promesse, &.product-vendu {
          background: #000;
          color: #f7f5f0;
        }
      }
    }
  }

  &-bloc {
    &-title {
      font-size: $font-size-18;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #333;
    }

    &-subtitle {
      font-size: $font-size-16;
      margin-bottom: 10px;

      &-agency {
        color: #9f9e9e;
      }
    }

    &-price {
      font-size: $font-size-22;
    }

    @include media-breakpoint-down(md) {
      display: block !important;
      margin: 30px 0;
      text-align: center !important;
    }
  }

  &-link {
    text-align: center;
    margin-top: 50px;
  }

  @include media-breakpoint-down(sm) {
    .bloc-title {
      margin-bottom: $size-30;
    }
  }
}

/*
|--------------------
|     LES AGENCES
|--------------------
*/
.agencies {
  margin-bottom: $size-100;
  padding: 100px;
  border: 1px solid #000;

  .bloc-description {
    max-width: 200px;
    margin-bottom: 40px;
  }

  &-list {
    &-title {
      li {
        padding: 5px 0;

        &.active, &:hover {
          h3 {
            a {
              color: #000;
              -webkit-text-fill-color: #000; /* Will override color (regardless of order) */
              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                bottom: -3px;
                left: 0;
                transition: transform 0.3s;
                transform-origin: bottom left;
                transform: scaleX(1);
                background-color: #000;
              }
            }
          }
        }

        h3 {
          padding-right: 30px;

          a {
            font-size: $font-size-34;
            font-weight: 700;
            color: transparent;
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
            white-space: nowrap;
          }
        }
      }
    }

    &-image {
      overflow: hidden;
      position: relative;
      width: 317px;
      display: flex;
      flex-shrink: 0;

      &-content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: -100%;
        z-index: 2;

        &.active {
          left: 0;
          position: static;
          z-index: 1;
        }

        &-text {
          position: absolute;
          display: inline-block;
          padding: 30px;
          background: rgba(255, 255, 255, 0.9);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: left;
          font-size: $font-size-16;
          letter-spacing: 0;
          @include media-breakpoint-down(xl) {
            padding: 10px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .bloc-title {
      text-align: center;
    }
    .bloc-description {
      max-width: 500px;
      margin: 0 auto 40px;
      text-align: center;
    }
    .bloc-buttons {
      margin-bottom: 50px;
      text-align: center;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 50px 10px;
    .agencies-list {
      justify-content: center !important;

      &-image {
        display: none;
      }

      &-title li h3 {
        padding: 0;
        text-align: center;
      }
    }
  }
}

/*
|--------------------
|     Actualités
|--------------------
*/
.news {
  margin-bottom: $size-100;

  .bloc-title {
    margin-bottom: $size-100;
  }

  &-picture {
    margin-bottom: $size-28;
    overflow: hidden;
    border: 1.25rem solid #fff;
  }

  &-title {
    h3 {
      font-weight: 700;
      font-size: $font-size-18;
      margin: 0 0 $size-20;
      padding: 0 $size-20;
    }
  }

  &-description {
    padding: 0 $size-20;
  }

  .container {
    margin-bottom: 40px;
  }

  @include media-breakpoint-down(sm) {
    .bloc-title {
      margin-bottom: $size-30;
    }
  }
}

/*
|--------------------
|     Instagram
|--------------------
*/
.instagram {
  margin-bottom: $size-100;
  background: #F7F5F0;
  padding: 0 $size-100 $size-50;

  .wpmi-list {
    li.wpmi-item {
      justify-content: center;

      img {
        border: $size-30 solid #fff;
        max-width: 100%;
        height: auto;
        @include media-breakpoint-down(xl) {
          border: $size-10 solid #fff;
        }
        @include media-breakpoint-down(md) {
          border: none;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    padding: $size-50;
  }
  @include media-breakpoint-down(md) {
    padding: $size-20;
  }
}

/*
|--------------------
|     Rejoignez-nous
|--------------------
*/

.join-us {
  margin-bottom: $size-100;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 230px;
    transform: translate(0, -50px);
    background: #fff;
    z-index: -1;
  }

  &-image {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50px);
      width: 230px;
      height: 230px;
      background: url('../img/global/bk-moriss-gray.svg');
      background-size: contain;
      z-index: -1;
    }
  }

  &-description {
    max-width: 220px;
    margin: 0 0 30px;
  }

  @include media-breakpoint-down(sm) {
    &-description {
      max-width: none;
      text-align: center;
    }
    .bloc-buttons {
      text-align: center;
    }
  }
}