.buy {
    .description {
        max-width: 800px;
        margin: 0 auto;
    }
}

/*
|--------------------
|     Filtres
|--------------------
*/
.filters {
    margin-bottom: 100px;
    position: relative;
    z-index: 1;
    &-group {
        margin: 30px 0 10px;
        &-label {
            margin-bottom: 0px;
        }
        .selectize-input {
            background: transparent;
            box-shadow: none;
            -webkit-box-shadow: none;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #000;
        }
        .selectize-dropdown .active {
            background: #f6f6f6;
        }

        .selectize-dropdown,
        .selectize-input,
        .selectize-input input {
            font-size: $size-14;
        }

        .nice-select {
            float: none;
        }
    }
    .row {
        .col-12 {
            em {
                color: #aaa;
                font-style: normal;
                font-size: $font-size-12;
            }
        }

        @include media-breakpoint-down(xxl) {
            input {
                width: calc(100% - 30px);
                background: transparent;
            }
        }
    }
}

/*
|--------------------
|     Single
|--------------------
*/

.product-bloc1 {
    margin-bottom: 150px;
    ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }
    iframe {
        border: none;
    }
    .bloc-title {
        .subtitle {
            margin-bottom: 20px;
        }
        .price {
            font-size: $font-size-30;
        }
    }
    @include media-breakpoint-down(sm) {
        .price {
            text-align: center;
        }
        .simpleParallax {
            margin-bottom: 10px;
        }
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 50px;
    }

    .products-images-line-link-wrap-view {
        font-size: $font-size-24;
    }
}

.product-bloc2 {
    margin-bottom: 120px;
    &-description {
        padding: 50px;
        border: 1px solid #000;
        .description {
            margin-bottom: 30px;
        }
        .description-2 {
            font-weight: 700;
        }
        &-informations {
            padding-top: 0;
            &-title {
                margin-top: 40px;
                margin-bottom: 40px;
                font-family: 'adobegaramond';
                font-size: $font-size-20;
                &:first-child {
                    margin-top: 0;
                }
            }
            &-col2, &-col3 {
                padding-top: 67px;
                .disable-animation{
                    &::after{
                        display: none;
                    }
                }
                sup{
                    padding: 1px 5px;
                    background: #3a3a3a;
                    color: #fff;
                    border-radius: 50%;
                    margin-left: 5px;
                    font-size: 10px;
                    @include media-breakpoint-down(lg) {
                        padding: 1px 6px;
                    }
                }
            }

        }
        .contact-form .gform_wrapper ul.gform_fields li.gfield {
            padding: 0;
            margin-top: 0;
        }
        .contact-form .gform_wrapper .top_label .gfield_label, .gform_wrapper legend.gfield_label {
            left: 0;
        }
    }

    .group-agencies-bloc {
        margin-bottom: 50px;
    }
    @include media-breakpoint-down(lg) {

        &-description {
            .description {
                margin-bottom: 30px;
            }
            &-informations {
                &-col2 .d-flex {
                    display: block !important;
                }
            }
            & > div {
                display: block !important;
            }
        }
    }
}