/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
    display: none;
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 0!important;
    background: #ccc;
    &-accept {
        margin-top: 10px;
        a.btn {
            font-size: $size-12;
        }
    }

    .item-close {
        font-size: 24px;
        cursor: pointer;
    }

    p {
        margin: 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px 0 10px 0;

        .item-close {
            font-size: 18px;
        }
    }
}


/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: $headerHeight;

    @include media-breakpoint-down(lg) {
        padding-top: $headerHeightSm;
    }
}

.bloc-title {
    margin-bottom: 30px;
}

.title {
    font-size: $font-size-46;
    font-weight: 700;
    @include media-breakpoint-down(xl) {
        font-size: $font-size-36;
    }
    @include media-breakpoint-down(md) {
        font-size: $font-size-26;
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}

.subtitle {
    font-size: $font-size-20;
    font-family: adobegaramond;
    @include media-breakpoint-down(md) {
        font-size: $font-size-14;
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}

.description {
    margin-bottom: 125px;
    h2 {
        font-weight: 700;
        font-size: $font-size-18;
        margin: $size-20 0;
    }
    h3 {
        font-weight: 700;
        font-size: $font-size-16;
        margin: $size-20 0;
    }
    h4 {
        font-weight: 700;
        font-size: $font-size-14;
        margin: $size-20 0;
    }
    ul {
        margin: 20px 0;
        li {
            padding: 0 0 5px 40px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 20px;
                background: #000;
                width: 4px;
                height: 4px;
            }
        }
    }
    strong {
        font-weight: 700;
    }
}

.top-bk {
    padding-top: 165px;
    @include media-breakpoint-down(sm) {
        padding-top: 120px;
    }
}

.top-bk-with-breadcrumb {
    padding-top: 165px;
    &:before {
        content: '';
        position: absolute;
        top: 30px;
        left: 30px;
        width: calc(100% - 60px);
        height: 770px;
        background: #F7F5F0;
        z-index: -1;
    }
    @include media-breakpoint-down(md) {
        padding-top: 120px;
        &:before {
            top: 10px;
            left: 10px;
            width: calc(100% - 20px);
        }
    }
}

.site-container {
    background: #f7f5f0;
    .splashscreen-right-line {
        position: relative;
        z-index: 2;
    }
}

.top-bk-with-breadcrumb-full {
    padding-top: 138px;
    background: #F7F5F0;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 300px;
        background: #F7F5F0;
        z-index: -1;
    }
}

.breadcrumbs {
    margin-bottom: 60px;
    position: relative;
    font-family: 'adobegaramond';
    a {
        font-family: 'adobegaramond';
        color: #7e7e7e;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }
}

.overflow-hidden {
    overflow: hidden;
}

a.no-hover {
    &:hover {
        &:after {
            display: none;
        }
    }
}

a.img-hover-link{
    img {
        transition: all 0.5s;
    }
    &:hover {
        img {
            transform: scale(1.1) !important;
        }
    }
}

.map {
    position: relative;
    display: inline-block;
    .marker {
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        transition: all 0.25s;
        &:hover {
            transform: scale(1.5);
        }
        &.active {
            transform: scale(1.5);
        }
    }
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    grid-auto-flow: dense;
    margin-bottom: 30px;
    .grid-item {
        position: relative;
        overflow: hidden;
        &-name {
            opacity: 0;
            position: absolute;
            background: #000;
            color: #fff;
            padding: 5px 50px;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, 0);
            transition: all 0.5s;
        }
        &:hover {
            .grid-item-name {
                opacity: 1;
            }
        }
    }
    .w-2 {
        grid-column-end: span 2;
        grid-row-end: span 1;
        height: 249px;
        img {
            transform: translate(0, -50px);
        }
    }
    .h-2 {
        grid-column-end: span 1;
        grid-row-end: span 2;
        height: 521px;
        img {
            width: auto;
            height: 100%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
    .sq-2 {
        grid-column-end: span 2;
        grid-row-end: span 2;
        img {
            max-width: 100%;
            object-fit: cover;
            position: relative;
        }
    }
    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-down(sm) {
        display: block;
        .grid-item {
            img {
                position: static;
                max-width: 100%;
                width: auto;
                height: auto;
                transform: translate(0, 0);
            }
            margin-bottom: 10px;
        }
        .w-2 {
            height: auto;
        }
        .h-2 {
            height: auto;
        }
        .sq-2 {
            height: auto;
        }
    }
}

.cms {
    margin-bottom: 120px;
}

#back-to-top {
    display: inline-block;
    border: 1px solid #000;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 60px;
    left: 120px;
    transition: background-color .3s,
    opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 8;
    &:after {
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        content: '';
        display: block;
        height: 10px;
        margin-top: -6px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        width: 10px;
        transform-origin: 66% 66%;
        transform: rotate(-135deg);
    }
    &:hover, &:active {
        background-color: #D4D2CE;
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
    @include media-breakpoint-down(md) {
        bottom: 20px;
        right: 20px;
    }
}

@include media-breakpoint-down(sm) {
    section, .products-images-line {
        margin-bottom: $size-30 !important;
    }
    .products-line:last-child .products-images-line {
        margin-bottom: 0 !important;
    }
}

.review {
    &-box {
        margin-bottom: 30px;
        &-quotation {
            background: #F7F5F0;
            padding: 20px;
            margin-bottom: 20px;
            position: relative;
            height: 200px;
            &-google {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 20px;
                height: 20px;
                a {
                    display: block;
                    height: 100%;
                }
            }
            &-text {
                position: relative;
                padding-left: 30px;
                &:before {
                    content: '';
                    background: url('../img/icon/quotation.svg');
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                }
            }
            &-stars {
                margin-bottom: 20px;
            }
            &-informations {
                &-picture {
                    margin-right: 20px;
                }
                &-text {
                    &-name {
                        font-weight: 700;
                    }
                    &-time {
                        font-size: $font-size-12;
                        color: #aaa;
                    }
                }
            }
        }
    }
    .bloc-buttons {
        margin-top: 30px;
    }
    @include media-breakpoint-down(md) {
        &-box {
            &-quotation {
                height: auto;
            }
        }
    }
}

.alert-custom {
    border: 1px solid #eeece7;
    border-radius: 0;
}

.referencement-menu {
    &-wrap-link {
        display: list-item;
        margin-bottom: 5px;
    }
}

.filter-mobile {
    display: none;
    overflow: visible;
    width: 300px;
    margin: 0 auto 30px;
}
@include media-breakpoint-down(md) {
    .filter-desktop {
        display: none !important;
    }

    .filter-mobile {
        display: block;
        margin: auto;
        text-align: center;
    }

    .team-images-content {
        margin-top: 30px;
    }

    .team-images-filters {
        margin-bottom: 30px !important;
    }
}

.nice-select {
    background: transparent !important;
}

.map-biens {
    text-align: center;
    svg {
        max-width: 100%;
        height: auto;

    }
    .path:hover polygon, .path:hover path, .path.active path, .path.active polygon {
        transition: all 0.25s;
        fill: #C4C3C3;
        cursor: pointer;
    }
    .path:hover text {
        cursor: pointer;
    }
}